import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { TDealStatus } from '../../../stores/logic/deals';
import { ActiveProps } from '../../../types';
import {
  FlexStartColumnContainer,
  RowContainer
} from '../../../utils/styling/general';
import StatusTag from '../../atoms/Layout/StatusTag';

interface Props {
  name: string;
  profileImage: string;
  status: TDealStatus;
  onClick: () => void;
  active: boolean;
  followers: number;
  waitingForEdit: boolean;
  workUpdated: boolean;
  message?: string;
  lastMessageAt?: string;
}

const InfluencerDetail = (props: Props) => {
  const {
    name,
    onClick,
    profileImage,
    status,
    active,
    followers,
    waitingForEdit,
    workUpdated,
    message,
    lastMessageAt
  } = props;
  return (
    <DetailContainer
      onClick={() => {
        onClick();
      }}
      active={active}
    >
      <GapRowContainer>
        <Image src={profileImage} />
        <GapColumnContainer>
          <Bu3Text>{name}</Bu3Text>
          <StatusTag
            status={status}
            followers={followers}
            waitingForEdit={waitingForEdit}
            workUpdated={workUpdated}
            message={message}
            lastMessageAt={lastMessageAt}
          />
        </GapColumnContainer>
      </GapRowContainer>
    </DetailContainer>
  );
};

export default InfluencerDetail;

const DetailContainer = styled.div<ActiveProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  padding: 8px 16px;
  justify-content: space-between;
  border-radius: 12px;
  background-color: ${props => (props.active ? COLOR.Gray_50 : COLOR.White)};
  :hover {
    cursor: pointer;
    background-color: ${COLOR.Gray_50};
  }
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  width: 100%;
`;

const GapColumnContainer = styled(FlexStartColumnContainer)`
  gap: 4px;
  width: 100%;
`;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 36px;
`;

const Bu3Text = styled(TEXT.Bu3_Reg)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
`;

const GrayMBu3Text = styled(TEXT.Bu3_Reg)`
  color: ${COLOR.Gray_M};
`;

const BoldBlue700B5Text = styled(TEXT.B5_Bold)`
  color: ${COLOR.Blue_700};
`;
