import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import {
  hexToRgba,
  toCapitalize,
  toDecimalNumber,
  toMonthYear
} from '../../../utils';
import useWindowDimensions from '../../../utils/hook/UseWindowDimensions';
import {
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';

import {
  ActiveElement,
  ArcElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  CoreScaleOptions,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Scale,
  Tick,
  Title,
  Tooltip
} from 'chart.js';
import { observer } from 'mobx-react';
import REmptyState from '../../../components/atoms/REmptyState';
import { ActiveProps } from '../../../types';
import { DealsChartData, PlatformType } from '../../../types/deal';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface BackgroundProps {
  color: string;
}

type LegendType = 'engagement' | 'reach' | 'impression';

const circleColors: Record<LegendType, string> = {
  engagement: COLOR.Blue_700,
  reach: COLOR.Lemon,
  impression: COLOR.Green_M
};

const circleStrokeColors: Record<LegendType, string> = {
  engagement: COLOR.Blue_50,
  reach: COLOR.Yellow_L,
  impression: COLOR.Green_L
};

interface GetColorStyleProps {
  color: string;
  strokeColor: string;
  index: number;
  hoveredIndex: number | null;
}

const getColorStyle = (props: GetColorStyleProps) => {
  const { color, strokeColor, index, hoveredIndex } = props;
  if (hoveredIndex === null)
    return {
      borderColor: color,
      backgroundColor: color,
      pointBorderColor: color,
      pointBackgroundColor: color,
      borderWidth: 2
    };
  const rgbaStyle = hexToRgba(color, 0.2) || color;
  const isHover = hoveredIndex === index;
  const changedColor = isHover ? color : rgbaStyle;
  return {
    borderColor: changedColor,
    backgroundColor: changedColor,
    pointBorderColor: color,
    pointBackgroundColor: color,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: strokeColor
  };
};

interface DataSetProps {
  insightData?: {
    labels: Date[];
    engagement: number[];
    reach: number[];
    impression: number[];
    view: number[];
  };
  hoveredIndex: number | null;
  platformActive: PlatformType;
  dateFormat: 'daily' | 'weekly' | 'monthly';
}

const getDataSet = (
  props: DataSetProps
): ChartData<'line', number[], string> => {
  const { insightData, hoveredIndex, dateFormat, platformActive } = props;
  if (!insightData) return { labels: [], datasets: [] };

  const { labels, engagement, reach, impression, view } = insightData;
  const dateLabel = labels.map(label => {
    if (dateFormat === 'monthly') return toMonthYear({ dueDate: label });
    return toMonthYear({ dueDate: label, haveDay: true, haveYear: false });
  });

  const defaultDataStyle = {
    pointHoverBorderWidth: 5,
    pointBorderWidth: 0,
    pointHitRadius: 50,
    tension: 0.1
  };

  const createDataset = (
    label: 'Engagement' | 'Reach' | 'Impression',
    data: number[],
    index: number
  ) => {
    const colorLabel = label.toLowerCase() as
      | 'engagement'
      | 'reach'
      | 'impression';
    return {
      label,
      data,
      hidden: index === 3,
      ...getColorStyle({
        color: circleColors[colorLabel],
        strokeColor: circleStrokeColors[colorLabel],
        index,
        hoveredIndex
      }),
      ...defaultDataStyle
    };
  };

  const isTikTok = platformActive === 'TIKTOK';

  return {
    labels: dateLabel,
    datasets: [
      createDataset('Engagement', engagement, 0),
      createDataset('Reach', isTikTok ? [] : reach, 1),
      createDataset('Impression', isTikTok ? view : impression, 2)
    ]
  };
};

interface LegendComponentProps {
  chartRef: React.MutableRefObject<
    ChartJSOrUndefined<'line', number[], string>
  >;
  type: LegendType;
  legendActive: Record<LegendType, { index: number; active: boolean }>;
  setLegendActive: React.Dispatch<
    React.SetStateAction<
      Record<
        LegendType,
        {
          index: number;
          active: boolean;
        }
      >
    >
  >;
  platformActive: PlatformType;
}

const onLegendClick = (props: LegendComponentProps) => {
  const { chartRef, type, legendActive, setLegendActive } = props;
  if (!!chartRef.current) {
    let tempLegendActive = { ...legendActive };
    const { active, index } = tempLegendActive[type];
    tempLegendActive[type].active = !active;
    setLegendActive(tempLegendActive);
    chartRef.current.getDatasetMeta(index).hidden = active;
    chartRef.current.update();
  }
};

const LegendComponent = (props: LegendComponentProps) => {
  const { type, legendActive, platformActive } = props;
  const colorActive = legendActive[type].active;

  if (type === 'reach' && platformActive === 'TIKTOK') return <></>;

  return (
    <ChartSelectorContainer onClick={() => onLegendClick({ ...props })}>
      <CheckCircle color={colorActive ? COLOR.Blue_700 : COLOR.White}>
        <RXIcon
          name="Check"
          color={colorActive ? COLOR.White : COLOR.Gray_400}
          size={16}
        />
      </CheckCircle>
      <GrayB4Text>{toCapitalize(type)}</GrayB4Text>
      <Circle color={circleColors[type]} />
    </ChartSelectorContainer>
  );
};

interface SwtichComponentProps {
  dateFormat: 'daily' | 'weekly' | 'monthly';
  setDateFormat: React.Dispatch<
    React.SetStateAction<'daily' | 'weekly' | 'monthly'>
  >;
  data: DealsChartData | undefined;
  setChartData: React.Dispatch<
    React.SetStateAction<ChartData<'line', number[], string> | undefined>
  >;
  hoveredIndex: number | null;
  platformActive: PlatformType;
}

const SwitchComponent = (props: SwtichComponentProps) => {
  const {
    dateFormat,
    setDateFormat,
    data,
    setChartData,
    hoveredIndex,
    platformActive
  } = props;
  return (
    <SwitchContainer>
      <SwitchButtonContainer
        active={dateFormat === 'daily'}
        onClick={() => {
          setDateFormat('daily');
          setChartData(
            getDataSet({
              insightData: data?.daily,
              hoveredIndex,
              dateFormat: 'daily',
              platformActive
            })
          );
        }}
      >
        <SwitchText active={dateFormat === 'daily'}>Daily</SwitchText>
      </SwitchButtonContainer>
      <SwitchButtonContainer
        active={dateFormat === 'weekly'}
        onClick={() => {
          setDateFormat('weekly');
          setChartData(
            getDataSet({
              insightData: data?.weekly,
              hoveredIndex,
              dateFormat: 'weekly',
              platformActive
            })
          );
        }}
      >
        <SwitchText active={dateFormat === 'weekly'}>Weekly</SwitchText>
      </SwitchButtonContainer>
      <SwitchButtonContainer
        active={dateFormat === 'monthly'}
        onClick={() => {
          setDateFormat('monthly');
          setChartData(
            getDataSet({
              insightData: data?.monthly,
              hoveredIndex,
              dateFormat: 'monthly',
              platformActive
            })
          );
        }}
      >
        <SwitchText active={dateFormat === 'monthly'}>Monthly</SwitchText>
      </SwitchButtonContainer>
    </SwitchContainer>
  );
};

interface HoverProps {
  activeElements: ActiveElement[];
  setHoveredIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

const handleHover = (props: HoverProps) => {
  const { activeElements, setHoveredIndex } = props;
  if (activeElements.length > 0) {
    const firstElement = activeElements[0];
    if (firstElement) {
      setHoveredIndex(firstElement.datasetIndex);
    }
  } else {
    setHoveredIndex(null);
  }
};

interface DateProps {
  dateInput: string | Date;
}

const getWeekRange = (props: DateProps) => {
  const { dateInput } = props;
  const date = new Date(dateInput);
  const start = new Date(date);
  const end = new Date(date);
  end.setDate(start.getDate() + 6);
  const dateRange = `${toMonthYear({
    dueDate: start,
    haveDay: true,
    haveYear: false
  })} - ${toMonthYear({
    dueDate: end,
    haveDay: true,
    haveYear: false
  })}`;
  return dateRange.toString();
};

const getMonthRange = (props: DateProps) => {
  const { dateInput } = props;
  const date = new Date(dateInput);
  const year = date.getFullYear();
  const month = date.getMonth();
  const start = new Date(year, month, 1);
  const end = new Date(year, month + 1, 0);

  const dateRange = `${toMonthYear({
    dueDate: start,
    haveDay: true,
    haveYear: false
  })} - ${toMonthYear({
    dueDate: end,
    haveDay: true,
    haveYear: false
  })}`;
  return dateRange;
};

interface Props {
  data: DealsChartData | undefined;
  dateFormat: 'daily' | 'weekly' | 'monthly';
  setDateFormat: React.Dispatch<
    React.SetStateAction<'daily' | 'weekly' | 'monthly'>
  >;
  platformActive: PlatformType;
}

const PerformanceChart = (props: Props) => {
  const { data, dateFormat, setDateFormat, platformActive } = props;

  const windowDimension = useWindowDimensions();
  const chartRef = useRef<
    ChartJSOrUndefined<'line', number[], string> | undefined
  >();

  const [legendActive, setLegendActive] = useState<
    Record<LegendType, { index: number; active: boolean }>
  >({
    engagement: { index: 0, active: true },
    reach: { index: 1, active: true },
    impression: { index: 2, active: true }
  });
  const [chartData, setChartData] =
    useState<ChartData<'line', number[], string>>();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const yAxisSetting = (
    color: string
  ): {
    border: {
      display: boolean;
      dash: number[];
    };
    grid: {
      color: string;
      tickBorderDash: number[];
    };
    ticks: {
      padding: number;
      // color: string;
      maxTicksLimit: number;
      stepSize: number;
      callback: (
        this: Scale<CoreScaleOptions>,
        value: string | number,
        index: number,
        ticks: Tick[]
      ) => string;
    };
  } => {
    return {
      border: {
        display: false,
        dash: [20, 1]
      },
      grid: {
        color: COLOR.Gray_50,
        tickBorderDash: [20, 50]
      },
      ticks: {
        // color,
        padding: 4,
        maxTicksLimit: 5,
        stepSize: 1,
        callback: function (value, index, ticks) {
          return toDecimalNumber({
            number: value,
            toFixed: 0,
            round: false,
            isZeroDash: false
          });
        }
      }
    };
  };

  useEffect(() => {
    setChartData(
      getDataSet({
        insightData: data?.[dateFormat],
        hoveredIndex,
        dateFormat,
        platformActive
      })
    );
  }, [!!data?.monthly, !!data?.weekly, hoveredIndex, platformActive]);

  const emptyStyle = {
    width: '100%',
    alignSelf: 'center',
    marginTop: 24,
    marginBottom: 24
  };

  if (!data)
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require('../../../assets/images/EmptyData.png')}
          />
        }
        header="กำลังรวบรวมข้อมูล"
        containerStyle={emptyStyle}
      />
    );

  if (
    data.daily.labels.length === 0 &&
    data.monthly.labels.length === 0 &&
    data.weekly.labels.length === 0
  )
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require('../../../assets/images/EmptyData.png')}
          />
        }
        header="ไม่มีข้อมูล"
        containerStyle={emptyStyle}
      />
    );

  return (
    <Container>
      <SpaceBetweenRowContainer style={{ width: '100%' }}>
        <HeaderContainer>
          <BoldH7Text>ภาพรวมทั้งหมด</BoldH7Text>
          <VerticalLine />
          <LegendComponent
            type="engagement"
            legendActive={legendActive}
            setLegendActive={setLegendActive}
            chartRef={chartRef}
            platformActive={platformActive}
          />
          <LegendComponent
            type="reach"
            legendActive={legendActive}
            setLegendActive={setLegendActive}
            chartRef={chartRef}
            platformActive={platformActive}
          />
          <LegendComponent
            type="impression"
            legendActive={legendActive}
            setLegendActive={setLegendActive}
            chartRef={chartRef}
            platformActive={platformActive}
          />
        </HeaderContainer>
        <SwitchComponent
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          data={data}
          setChartData={setChartData}
          hoveredIndex={hoveredIndex}
          platformActive={platformActive}
        />
      </SpaceBetweenRowContainer>
      <Chart
        ref={chartRef}
        type="line"
        data={
          chartData ?? {
            labels: [],
            datasets: []
          }
        }
        width={(windowDimension.width - 240) * 0.96}
        height={338}
        options={{
          animation: {},
          onHover: (e, activeElements) =>
            handleHover({ activeElements, setHoveredIndex }),
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              backgroundColor: 'rgba(0,0,0,0.8)',
              titleColor: COLOR.White,
              bodyColor: COLOR.White,
              borderColor: 'rgba(255,255,255,0.8)',
              borderWidth: 1,
              padding: 10,
              displayColors: false,
              callbacks: {
                label: tooltipItem => {
                  return `${tooltipItem.dataset.label}: ${toDecimalNumber({
                    number: tooltipItem.raw as number,
                    isZeroDash: false
                  })}`;
                },
                title: title => {
                  if (dateFormat === 'daily') return title[0].label;
                  if (dateFormat === 'weekly')
                    return getWeekRange({ dateInput: title[0].label });
                  return getMonthRange({ dateInput: title[0].label });
                }
              }
            }
          },
          elements: {
            point: {
              radius: 1,
              hoverRadius: 8
            }
          },
          scales: {
            x: {
              border: {
                display: true
              },
              grid: {
                tickBorderDash: [20, 50]
              }
            },
            y: {
              ...yAxisSetting(circleColors.engagement)
            }
          }
        }}
        plugins={[
          {
            id: 'mouseOut',
            beforeEvent: (chart, args) => {
              const event = args.event;
              if (event.type === 'mouseout') {
                setHoveredIndex(null);
              }
            }
          }
        ]}
      />
    </Container>
  );
};

export default observer(PerformanceChart);

const Container = styled(FlexStartColumnContainer)`
  gap: 36px;
  width: 100%;
  position: relative;
`;

const HeaderContainer = styled(RowContainer)`
  gap: 8px;
`;

const ChartSelectorContainer = styled(RowContainer)`
  background-color: ${COLOR.Gray_50};
  padding: 8px 12px;
  border-radius: 40px;
  gap: 4px;
  :hover {
    cursor: pointer;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  height: 48px;
  padding: 4px;
  align-items: flex-start;
  border-radius: 40px;
  border: 0.6px solid ${COLOR.Gray_300};
  margin-right: 20px;
`;

const SwitchButtonContainer = styled.div<ActiveProps>`
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background-color: ${props => (props.active ? COLOR.Black : 'none')};
  :hover {
    cursor: pointer;
  }
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 20px;
  border-right: 1px solid ${COLOR.Gray_300};
  margin: 0px 8px 0px 8px;
`;

const Circle = styled.div<BackgroundProps>`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: ${props => props.color};
`;

const CheckCircle = styled.div<BackgroundProps>`
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background-color: ${props => props.color};
`;

const BoldH7Text = styled(TEXT.H7_Bold)``;

const GrayB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_D};
`;

const SwitchText = styled(TEXT.Bu3_Reg)<ActiveProps>`
  color: ${props => (props.active ? COLOR.White : COLOR.Gray_D)};
  font-weight: ${props => (props.active ? 700 : 400)};
`;
