import { Alert, Slide, Snackbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import Instagram from '../../assets/images/PlatformLogos/Instagram.svg';
import Tiktok from '../../assets/images/PlatformLogos/Tiktok.svg';
import { COLOR } from '../../fonts/color';
import { TEXT } from '../../fonts/text';
import { TDealStatus } from '../../stores/logic/deals';
import { ActiveProps } from '../../types';
import { ChatMessage } from '../../types/chatMessage';
import { StatusType } from '../../types/deal';
import {
  PLACEHOLDER_PROFILE,
  difTime,
  toDecimalNumber,
  toISODate
} from '../../utils';
import { determinePage } from '../../utils/Navigation';
import {
  FlexStartColumnContainer,
  SpaceBetweenRowContainer
} from '../../utils/styling/general';
import MoreOptions, { OptionProps } from './MoreOptions';

const OptionsComponent = (props: Props) => {
  const {
    openedMenu,
    dealContactId,
    status,
    deliverStatus,
    changeDeliverStatus,
    changeInfluDeliverStatus,
    setOpenedMenu,
    setDealContactId,
    setRejectActive
  } = props;

  if (openedMenu !== dealContactId) return <></>;

  const options: Array<OptionProps> = [
    {
      title: 'แจ้งส่งของ/รับบริการ',
      color: COLOR.Black,
      icon: 'Parcel',
      iconColor: COLOR.Black,
      action: () => {
        let sentStatus: 'DELIVERED' | 'TODELIVER' = 'DELIVERED';
        if (deliverStatus === 'DELIVERED') {
          sentStatus = 'TODELIVER';
        }
        changeDeliverStatus({ dealContactId, status: sentStatus });
        changeInfluDeliverStatus({ dealContactId, status: sentStatus });
        setOpenedMenu('');
      }
    },
    {
      title: 'ยกเลิกการร่วมงาน',
      color: COLOR.Warning,
      icon: 'Close',
      iconColor: COLOR.Warning,
      action: () => {
        setDealContactId(dealContactId);
        setRejectActive(true);
        setOpenedMenu('');
      }
    }
  ];
  if (status === 'ongoing') return <MoreOptions options={options} />;
  if (status === 'pending') return <MoreOptions options={options.slice(-1)} />;
  return <></>;
};

interface Props {
  name: string;
  dealId: string;
  image?: string;
  status: StatusType;
  igFollower: number;
  tkFollower: number;
  message?: string;
  date: Date;
  dealContactId: string;
  isInvited: boolean;
  isNegotiating: boolean;
  unread: boolean;
  dueDate?: Date;
  deliverStatus: string | undefined;
  getLastMessage?: (dealContactId: string) => void;
  unsubscribeLastMessage?: (dealContactId: string) => void;
  lastMessage: Record<string, ChatMessage>;
  setDealContactId: React.Dispatch<React.SetStateAction<string>>;
  setRejectActive: React.Dispatch<React.SetStateAction<boolean>>;
  accountId: string;
  influStatus: TDealStatus;
  influencerId: string;
  changeDeliverStatus: ({
    dealContactId,
    status
  }: {
    dealContactId: string;
    status: 'DELIVERED' | 'TODELIVER';
  }) => Promise<void>;
  changeInfluDeliverStatus: ({
    dealContactId,
    status
  }: {
    dealContactId: string;
    status: string;
  }) => Promise<void>;
  openedMenu: string;
  setOpenedMenu: React.Dispatch<React.SetStateAction<string>>;
  shouldUpdate: boolean;
  toggleShouldUpdate: () => void;
  haveChatNoti: boolean;
}

interface CircleProps {
  color: string;
  active: boolean;
}

const InfluencerCards = (props: Props) => {
  const {
    name,
    dealId,
    image,
    status,
    igFollower,
    tkFollower,
    message,
    date = new Date(),
    dealContactId,
    isInvited,
    isNegotiating,
    unread,
    dueDate = new Date().toString(),
    deliverStatus,
    getLastMessage,
    lastMessage,
    influStatus,
    changeDeliverStatus,
    changeInfluDeliverStatus,
    openedMenu,
    setOpenedMenu,
    shouldUpdate,
    toggleShouldUpdate,
    haveChatNoti
  } = props;

  const navigate = useNavigate();
  const [activeSnackbar, setActiveSnackbar] = useState<boolean>(false);

  const onNavigate = () => {
    navigate(
      `/influencer?dealId=${dealId}&dealContactId=${dealContactId}&status=${status}&page=${determinePage(
        status
      )}`,
      { state: status }
    );
  };

  const onNavigateChat = () => {
    navigate(
      `/influencer?dealId=${dealId}&dealContactId=${dealContactId}&status=${status}&page=chat`,
      { state: status }
    );
  };

  const [timeRemain] = difTime(dueDate.toString(), false, true, true).split(
    ' '
  );

  const transitionProp: React.ComponentType<
    TransitionProps & {
      children: React.ReactElement<any, any>;
    }
  > = Slide;

  const lastMes: ChatMessage =
    status !== 'pending' ? lastMessage[dealContactId] : {};

  const setMessage = async () => {
    try {
      if (getLastMessage && lastMessage) {
        getLastMessage(dealContactId);
      }
    } catch (error) {
      console.log('no message');
    }
  };

  useEffect(() => {
    setMessage();
  }, []);

  useEffect(() => {
    setMessage();
    if (shouldUpdate) {
      toggleShouldUpdate();
    }
  }, [shouldUpdate]);

  const checkMessageMedia = (message: ChatMessage) => {
    if (Object(message).hasOwnProperty('video')) {
      return 'วิดีโอ';
    } else if (Object(message).hasOwnProperty('image')) {
      return 'รูปภาพ';
    } else if (Object(message).hasOwnProperty('text')) {
      return message.text;
    }
    return '';
  };

  const showIcon = () => {
    return (
      checkMessageMedia(lastMes) === 'วิดีโอ' ||
      checkMessageMedia(lastMes) === 'รูปภาพ'
    );
  };

  const renderInfluInfo = () => {
    switch (status) {
      case 'pending':
        return <B4Text>เหลือเวลาตอบรับ {timeRemain} วัน</B4Text>;
      default:
        const messageInfo = lastMessage[dealContactId];
        const messageTimestamp = messageInfo?.timestamp
          ? difTime(toISODate(messageInfo.timestamp)) + ' ago'
          : '';
        return <B4Text>{messageTimestamp}</B4Text>;
    }
  };

  const renderNewRequest = () => {
    switch (status) {
      case 'pending':
        return (
          <StatusContainer>
            <NewRequestContainer>
              {unread && <ColorCircle color={COLOR.Blue_700} active={true} />}
              <BoldH8Text>คำขอใหม่</BoldH8Text>
            </NewRequestContainer>
            {renderRequestFrom()}
          </StatusContainer>
        );
      case 'ongoing':
        return (
          <StatusContainer>
            <NewRequestContainer>
              {unread && <ColorCircle color={COLOR.Blue_700} active={true} />}
              <BoldH8Text>กำลังดำเนินการ</BoldH8Text>
            </NewRequestContainer>
            {deliverStatus && deliverStatus === 'DELIVERED'
              ? renderOngoingStatus('sendItems')
              : renderOngoingStatus('normal')}
          </StatusContainer>
        );
      case 'check':
        return (
          <StatusContainer>
            <NewRequestContainer>
              {unread && <ColorCircle color={COLOR.ORANGE} active={true} />}
              <BoldH8Text>ตรวจงาน</BoldH8Text>
            </NewRequestContainer>
            {renderCheckWork('updated')}
          </StatusContainer>
        );
      case 'done':
        let doneStatus = 'normal';
        if (influStatus !== 'done') {
          doneStatus = 'cancelled';
        }
        return (
          <StatusContainer>
            <NewRequestContainer>
              {unread && <ColorCircle color={COLOR.Gray_M} active={true} />}
              <BoldH8Text>เสร็จสิ้น</BoldH8Text>
            </NewRequestContainer>
            {renderDoneWork(doneStatus)}
          </StatusContainer>
        );
    }
  };

  const renderRequestFrom = () => {
    if (isNegotiating) {
      return (
        <RequestFromContainer>
          <RXIcon name="Nego" size={20} />
          <B4Text style={{ marginLeft: '4px', marginTop: '3px' }}>
            ยื่นข้อเสนอ
          </B4Text>
        </RequestFromContainer>
      );
    } else if (isInvited) {
      return (
        <RequestFromContainer>
          <RXIcon name="MailSend" size={20} />
          <B4Text style={{ marginLeft: '4px', marginTop: '2px' }}>
            มาจากคำเชิญ
          </B4Text>
        </RequestFromContainer>
      );
    } else {
      return (
        <RequestFromContainer style={{ marginTop: '2px' }}>
          <B4Text>ขอเข้าร่วมปกติ</B4Text>
        </RequestFromContainer>
      );
    }
  };

  const renderOngoingStatus = (ongoingStatus: string) => {
    switch (ongoingStatus) {
      case 'normal':
        return (
          <SendPackageContainer
            active={false}
            onClick={e => {
              e.stopPropagation();
              changeDeliverStatus({ dealContactId, status: 'DELIVERED' });
              changeInfluDeliverStatus({ dealContactId, status: 'DELIVERED' });
            }}
          >
            <RXIcon name="Parcel" size={20} />
            <B4Text style={{ marginLeft: '4px' }}>แจ้งส่งของ/รับบริการ</B4Text>
          </SendPackageContainer>
        );
      case 'sendItems':
        return (
          <SentPackageContainer
            active={true}
            onClick={e => {
              e.stopPropagation();
              changeDeliverStatus({ dealContactId, status: 'TODELIVER' });
              changeInfluDeliverStatus({ dealContactId, status: 'TODELIVER' });
            }}
          >
            <RXIcon name="MailSend" size={20} />
            <B4Text style={{ marginLeft: '4px' }}>ส่งของ/รับบริการแล้ว</B4Text>
          </SentPackageContainer>
        );
      case 'workPublished':
        return (
          <RequestFromContainer>
            <RXIcon name="Nego" size={20} />
            <B4Text style={{ marginLeft: '4px' }}>ยื่นข้อเสนอ</B4Text>
          </RequestFromContainer>
        );
    }
  };

  const renderCheckWork = (requestFrom: string) => {
    switch (requestFrom) {
      case 'normal':
        return (
          <RequestFromContainer style={{ marginTop: '2px' }}>
            <RXIcon name="File" size={20} />
            <B4Text style={{ marginLeft: '4px' }}>ตรวจงาน</B4Text>
          </RequestFromContainer>
        );
      case 'waitForEdit':
        return (
          <RequestFromContainer>
            <RXIcon name="Edit" size={20} />
            <B4Text style={{ marginLeft: '4px' }}>รอการแก้ไข</B4Text>
          </RequestFromContainer>
        );
      case 'updated':
        return (
          <RequestFromContainer>
            <RXIcon name="Edit" size={20} />
            <B4Text style={{ marginLeft: '4px' }}>มีการอัพเดท</B4Text>
          </RequestFromContainer>
        );
    }
  };

  const renderDoneWork = (doneStatus: string) => {
    switch (doneStatus) {
      case 'normal':
        return (
          <RequestFromContainer style={{ marginTop: '2px' }}>
            <RXIcon name="CheckCircle" size={20} />
            <B4Text style={{ marginLeft: '4px' }}>เสร็จสิ้น</B4Text>
          </RequestFromContainer>
        );
      case 'cancelled':
        return (
          <RequestFromContainer>
            <RXIcon name="CloseCircle" size={20} />
            <B4Text style={{ marginLeft: '4px' }}>ยกเลิก</B4Text>
          </RequestFromContainer>
        );
    }
  };

  const renderFollower = () => {
    if (status === 'pending') {
      return (
        <FollowerContainer>
          <PlatformContainer>
            <Instagram width={20} height={20} />
            <BoldB4Text style={{ marginLeft: '4px', width: '48px' }}>
              {toDecimalNumber({ number: igFollower })}
            </BoldB4Text>
          </PlatformContainer>
          <PlatformContainer style={{ marginLeft: '0px' }}>
            <Tiktok width={20} height={20} />
            <BoldB4Text style={{ marginLeft: '4px' }}>
              {toDecimalNumber({ number: tkFollower })}
            </BoldB4Text>
          </PlatformContainer>
        </FollowerContainer>
      );
    } else {
      return (
        <FollowerContainer>
          <B4Text style={{ marginTop: '5px', marginRight: '5px' }}>
            {message}
          </B4Text>
          <SpaceBetweenRowContainer
            onClick={e => {
              onNavigateChat();
              e.stopPropagation();
            }}
            style={{ width: '100px' }}
          >
            {showIcon() && <RXIcon name="Attach" size={16} />}
            <B4Text
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {checkMessageMedia(lastMes)}
            </B4Text>
            <IconContainer>
              <RXIcon name="Chat" color={COLOR.Blue_700} size={24} />
              {haveChatNoti && <NotificationContainer />}
            </IconContainer>
          </SpaceBetweenRowContainer>
        </FollowerContainer>
      );
    }
  };

  return (
    <>
      <Container onClick={onNavigate}>
        <NameContainer>
          <ImageContainer src={image || PLACEHOLDER_PROFILE} />
          <FlexStartColumnContainer>
            <BoldH8Text
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {name}
            </BoldH8Text>
            {renderInfluInfo()}
          </FlexStartColumnContainer>
        </NameContainer>
        {renderNewRequest()}
        {renderFollower()}
        <DateContainer>
          <B4Text>
            {new Date(date).toLocaleDateString('en-US', {
              timeZone: 'Asia/Jakarta',
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })}{' '}
            {new Date(date).toLocaleTimeString('it-IT', {
              timeZone: 'Asia/Jakarta',
              hour: '2-digit',
              minute: '2-digit'
            })}
          </B4Text>
        </DateContainer>
        <MoreButton
          active={status === 'pending' || status === 'ongoing'}
          onClick={e => {
            e.stopPropagation();
            // TODO: Change this into a common use of drop down instead, make all drop down close when out of focus
            if (openedMenu === dealContactId) {
              setOpenedMenu('');
              return;
            }
            setOpenedMenu(dealContactId);
          }}
        >
          <RXIcon name="MoreVertical" color={COLOR.Gray_300} />
        </MoreButton>
        {/* // TODO: Once added saved state for the icon, add the changing text as well like in InfluencerDetailHeader */}
        <Snackbar
          open={activeSnackbar}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={() => setActiveSnackbar(false)}
          TransitionComponent={transitionProp}
          key={'saveSnackbar'}
        >
          <Alert
            icon={<RXIcon name="CheckCircle" color={COLOR.WHITE} />}
            variant="filled"
            onClose={() => setActiveSnackbar(false)}
            sx={{
              height: '48px',
              width: '344px',
              bgcolor: COLOR.BLACK,
              alignItems: 'center',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
            }}
          >
            <WhiteB3Text>
              บันทึกไว้ที่ <Blue300BoldB3Text>Favorites</Blue300BoldB3Text>{' '}
              เรียบร้อยแล้ว
            </WhiteB3Text>
          </Alert>
        </Snackbar>
        <OptionsComponent {...props} />
      </Container>
    </>
  );
};

export default observer(InfluencerCards);

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 74px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 17px 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

  :hover {
    cursor: pointer;
  }
`;

const ImageContainer = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 50px;
  background: ${COLOR.Gray_300};
  object-fit: cover;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 180px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 4px;
  align-items: center;
  width: 180px;
`;

const NewRequestContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RequestFromContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SendPackageContainer = styled.div<ActiveProps>`
  display: flex;
  align-items: center;
  padding: 0px 8px 0px 8px;
  /* border: ; */
  border: ${props =>
    props.active
      ? `1px solid ${COLOR.Blue_50}`
      : `1px solid ${COLOR.Blue_400}`};
  border-radius: 20px;
  background: ${props => (props.active ? COLOR.Blue_50 : COLOR.WHITE)};
  :hover {
    cursor: pointer;
  }
`;

const SentPackageContainer = styled.div<ActiveProps>`
  display: flex;
  align-items: center;
  padding: 0px 8px 0px 8px;
  border: 1px solid ${COLOR.LIGHTGREEN2};
  border-radius: 20px;
  background: ${props => (props.active ? COLOR.LIGHTGREEN2 : COLOR.WHITE)};
  :hover {
    cursor: auto;
  }
`;

const FollowerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 180px;
`;

const PlatformContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
`;

const IconContainer = styled.div`
  position: relative;
`;

const NotificationContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  background-color: ${COLOR.PINK};
  top: 3px;
  right: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

const MoreButton = styled.div<ActiveProps>`
  position: absolute;
  right: 10px;
  display: ${props => (props.active ? 'flex' : 'none')};
  padding: 20px;
`;

const BoldH8Text = styled(TEXT.H8_Bold)``;

const WhiteB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.White};
`;

const Blue300BoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.Blue_300};
`;

const BoldB4Text = styled(TEXT.B4_Bold)`
  margin-top: 2px;
`;

const B4Text = styled(TEXT.B4_Reg)``;

const ColorCircle = styled.div<CircleProps>`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50px;
  background: ${props => props.color};
  opacity: ${props => (props.active ? 1 : 0)};
`;
