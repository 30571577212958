import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../../fonts/color';
import { ActiveProps } from '../../../../types';
import { PlatformType } from '../../../../types/deal';
import { renderCapatalizedSocialPlatform } from '../../../../utils';
import { RowContainer } from '../../../../utils/styling/general';
import ANDCheckbox from '../../../atoms/ANDCheckbox';
import { TEXT } from '../../../../fonts/text';

interface PlatformProps {
  isSelected: boolean;
}
interface Props {
  platform: PlatformType;
  setPlatform: (platform: PlatformType) => void;
}
const PlatformSelector = (props: Props) => {
  const { platform, setPlatform } = props;
  const PlatformSelectionBox = ({
    platformType
  }: {
    platformType: PlatformType;
  }) => {
    const isSelected = platform === platformType;

    return (
      <PlatformContainer
        isSelected={isSelected}
        onClick={() => setPlatform(platformType)}
      >
        <ANDCheckbox
          active={isSelected}
          containerStyle={{ borderRadius: 20 }}
        />
        <GapRowContainer>
          <PlatformLogo platformType={platformType} />
          <ButtonSmallText active={isSelected}>
            {renderCapatalizedSocialPlatform(platformType)}
          </ButtonSmallText>
        </GapRowContainer>
      </PlatformContainer>
    );
  };

  const PlatformLogo = ({ platformType }: { platformType: PlatformType }) => {
    switch (platformType) {
      case 'INSTAGRAM':
        return (
          <Image
            src={require('../../../../assets/images/PlatformLogos/Instagram.png')}
          />
        );
      case 'TIKTOK':
        return (
          <Image
            src={require('../../../../assets/images/PlatformLogos/Tiktok.png')}
          />
        );
      case 'LEMON':
        return (
          <Image
            src={require('../../../../assets/images/PlatformLogos/Lemon.png')}
          />
        );
      case 'FACEBOOK':
        return <RXIcon name="FacebookCircle" color={COLOR.Blue_700} />;
    }
  };

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>ช่องทาง</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <RXIcon name="PlaceholderFill" color={COLOR.Blue_700} size={20} />
          <Body3Text>
            เลือก <UnderlineBody3Text>แพลตฟอร์ม</UnderlineBody3Text>{' '}
            โซเชียลมีเดียที่ต้องการโปรโมตแคมเปญ
            <RedHeader7Text>*</RedHeader7Text>
          </Body3Text>
        </GapRowContainer>
        {/* <Body3Text>เลือกแพลตฟอร์มโซเชียลมีเดียที่ต้องการโปรโมตแคมเปญ</Body3Text> */}
        <GapRowContainer>
          <PlatformSelectionBox platformType="INSTAGRAM" />
          <PlatformSelectionBox platformType="TIKTOK" />
          <PlatformSelectionBox platformType="FACEBOOK" />
          <PlatformSelectionBox platformType="LEMON" />
        </GapRowContainer>
      </ContentContainer>
    </Container>
  );
};

export default PlatformSelector;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  min-width: 688px;
  margin-top: 72px;
`;

const ContentContainer = styled.div`
  width: 568px;
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const PlatformContainer = styled.div<PlatformProps>`
  display: flex;
  padding: 16px 24px 16px 16px;
  width: 136px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  border: 0.6px solid
    ${props => (props.isSelected ? COLOR.Blue_700 : COLOR.Gray_200)};
  background-color: ${props => (props.isSelected ? COLOR.Blue_50 : null)};

  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 20px;
`;

const BoldHeader7Text = styled(TEXT.H7_Bold)`
  width: 88px;
  white-space: nowrap;
`;

const RedHeader7Text = styled(TEXT.H7_Reg)`
  width: 88px;
  white-space: nowrap;
  color: ${COLOR.PINK};
  margin-left: 2px;
`;

const Body3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_D};
`;

const UnderlineBody3Text = styled(TEXT.B3_Bold)`
  text-decoration: underline;
`;

const ButtonSmallText = styled(TEXT.Bu3_Reg)<ActiveProps>`
  color: ${props => (props.active ? COLOR.Blue_700 : COLOR.BLACK)};
  font-weight: ${props => (props.active ? 700 : 400)};
  white-space: nowrap;
`;
