import { observer } from 'mobx-react';
import { SetStateAction, useEffect, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import RLoading from '../../components/atoms/RLoading';
import ScrollToTop from '../../components/atoms/ScrollToTop';
import AcceptInfluModal from '../../components/molecules/CampaignManagement/AcceptInfluModal';
import ProfileFullScreenModal from '../../components/molecules/CampaignManagement/ProfileFullScreenModal';
import WorkModal from '../../components/molecules/CampaignManagement/WorkModal';
import DealCardHeader from '../../components/molecules/Deal/DealCardHeader';
import DealDetails from '../../components/organisms/CampaignManagement/DealDetails';
import InfluencerChat from '../../components/organisms/CampaignManagement/InfluencerChat';
import InfluencerCheckWork from '../../components/organisms/CampaignManagement/InfluencerCheckWork';
import InfluencerPortfolio from '../../components/organisms/Influencer/InfluencerPortfolio';
import {
  useAccountStore,
  useChatStore,
  useDealStore,
  useInfluencerStore,
  useNotificationStore
} from '../../stores/initialize/Context';
import { Work } from '../../types/work';
import { useQuery } from '../../utils';
import { GET } from '../../utils/Networking';
import { Deal } from '../../types/deal';
import { IGetInfluencerResponse } from '../../types/api/getInfluencer';
import { ChatBanners, ChatMessage } from '../../types/chatMessage';
import { IInfluencerList } from '../../types/api/listInfluencers';
import { User } from 'react-web-gifted-chat';
import { Account } from '../../types/account';

interface DisplayImageProps {
  workList: Work[];
  index: number;
  setWorkList: React.Dispatch<React.SetStateAction<Work[]>>;
  setIndex: (value: number) => void;
}

const displayImage = (props: DisplayImageProps) => {
  const { workList, index, setWorkList, setIndex } = props;
  setWorkList(workList);
  setIndex(index);
};

interface ContentProps {
  page: 'profile' | 'chat' | 'work';
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  setStartIndex: React.Dispatch<React.SetStateAction<number>>;
  finishWorkModal: boolean;
  setFinishWorkModal: React.Dispatch<React.SetStateAction<boolean>>;
  lastOnline: string;
  isLoading: boolean;
  showDealDetail: boolean;
  setShowDealDetail: React.Dispatch<React.SetStateAction<boolean>>;
  removeToReviewInflu: ({ dealContactId }: { dealContactId: string }) => void;
  deal: Deal;
  influencer: IGetInfluencerResponse | undefined;
  send: (dealContactId: string, messages: ChatMessage[]) => void;
  messages: Record<string, ChatMessage[]>;
  subscribeToChat: (dealContactId: string) => void;
  canLoadEarlier: boolean;
  dealContactId: string;
  loading: boolean;
  influencers: Record<
    'pending' | 'ongoing' | 'check' | 'done',
    IInfluencerList
  >;
  loadEarlier: (dealContactId: string) => void;
  toggleShouldUpdate: () => void;
  shouldUpdate: boolean;
  sendImages: (
    dealContactId: string,
    images: File,
    user: User
  ) => Promise<void>;
  sendVideos: (dealContactId: string, video: File, user: User) => Promise<void>;
  getChatBannerDetail: ({
    dealContactId
  }: {
    dealContactId: string;
  }) => Promise<void>;
  banners: Record<string, ChatBanners>;
  bannerLoading: boolean;
  readChatMessage: ({
    brandId,
    dealContactId
  }: {
    brandId: string;
    dealContactId: string;
  }) => Promise<void>;
  account: Account;
  popChatInfluencerToTop: (dealContactId: string) => void;
  setAcceptModalActive: React.Dispatch<React.SetStateAction<string>>;
  navigate: NavigateFunction;
  getDealInfluReview: ({
    dealContactId,
    influencerName,
    rate,
    reviewTag,
    comment
  }: {
    dealContactId: string;
    influencerName: string;
    rate: string;
    reviewTag: number[];
    comment: string;
  }) => Promise<void>;
  setWorkList: React.Dispatch<React.SetStateAction<Work[]>>;
}

const ContentComponent = (props: ContentProps) => {
  const {
    page,
    setIndex,
    setStartIndex,
    setWorkList,
    finishWorkModal,
    setFinishWorkModal,
    lastOnline,
    isLoading,
    showDealDetail,
    setShowDealDetail,
    removeToReviewInflu,
    deal,
    influencer,
    send,
    messages,
    subscribeToChat,
    canLoadEarlier,
    dealContactId,
    loading,
    influencers,
    loadEarlier,
    toggleShouldUpdate,
    shouldUpdate,
    sendImages,
    sendVideos,
    getChatBannerDetail,
    bannerLoading,
    banners,
    readChatMessage,
    account,
    popChatInfluencerToTop,
    setAcceptModalActive,
    navigate,
    getDealInfluReview
  } = props;
  if (isLoading) {
    return <RLoading containerStyle={{ marginTop: '150px' }} />;
  }

  if (showDealDetail) {
    return (
      <DealContainer>
        <DealCardHeader
          name={deal.name}
          // @ts-ignore
          photos={deal.photos.map(photo => {
            return { url: photo };
          })}
          category={deal.category}
          value={deal.value}
          cash={deal.cash}
          // @ts-ignore
          criteria={deal.criteria}
          checkDetail={() => {}}
          isDraft={false}
          haveBack={() => setShowDealDetail(false)}
        />
        <DealDetails deal={deal} />
      </DealContainer>
    );
  }

  switch (page) {
    case 'profile':
      if (!!influencer) {
        return (
          <InfluencerPortfolio
            influencer={influencer}
            setAcceptModalActive={setAcceptModalActive}
          />
        );
      }
      return <></>;
    case 'chat':
      return (
        <InfluencerChat
          influencer={influencer}
          lastOnline={lastOnline}
          dealContactId={dealContactId}
          send={send}
          messages={messages}
          subscribeToChat={subscribeToChat}
          canLoadEarlier={canLoadEarlier}
          loading={loading}
          loadEarlier={loadEarlier}
          toggleShouldUpdate={toggleShouldUpdate}
          shouldUpdate={shouldUpdate}
          sendImages={sendImages}
          sendVideos={sendVideos}
          getChatBannerDetail={getChatBannerDetail}
          banners={banners}
          bannerLoading={bannerLoading}
          account={account}
          popChatInfluencerToTop={popChatInfluencerToTop}
          dealId={deal.dealId}
          submissionDate={deal.submissionDate}
          readChatMessage={readChatMessage}
        />
      );
    case 'work':
      return (
        <InfluencerCheckWork
          influencer={influencer}
          influencers={influencers}
          lastOnline={lastOnline}
          dealContactId={dealContactId}
          displayImage={(workList, index) =>
            displayImage({ workList, setWorkList, setIndex, index })
          }
          openFinishWorkModal={() => {
            setFinishWorkModal(true);
          }}
          navigate={navigate}
          brandAccount={account}
          finishWorkModal={finishWorkModal}
          closeFinishModal={() => setFinishWorkModal(false)}
          getDealInfluReview={getDealInfluReview}
          removeToReviewInflu={removeToReviewInflu}
        />
      );
  }
};

const InfluencerManagement = () => {
  let query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const determinedStatus = location.state as
    | 'pending'
    | 'ongoing'
    | 'check'
    | 'done';

  const dealId = query.get('dealId') as string;
  const dealContactId = query.get('dealContactId') as string;
  const page = query.get('page') as 'profile' | 'chat' | 'work';

  const [status, setStatus] = useState<
    'pending' | 'ongoing' | 'check' | 'done'
  >(determinedStatus);
  const [workList, setWorkList] = useState<Work[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [imageList, setImageList] = useState<string[]>([]);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [acceptModalActive, setAcceptModalActive] = useState<string>('');
  const [finishWorkModal, setFinishWorkModal] = useState<boolean>(false);
  const [lastOnline, setLastOnline] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showDealDetail, setShowDealDetail] = useState<boolean>(false);

  const {
    influencer,
    influencers,
    getInfluencer,
    removePendingInflu,
    removeToReviewInflu,
    listInfluencers,
    loading: influLoading,
    popChatInfluencerToTop
  } = useInfluencerStore();
  const { data, metadata, next } = influencers[status] || {};
  const { deal, getDeal, approve, reject, getDealInfluReview } = useDealStore();
  const { getAuthToken, account } = useAccountStore();
  const {
    send,
    messages,
    subscribeToChat,
    canLoadEarlier,
    loading,
    loadEarlier,
    toggleShouldUpdate,
    shouldUpdate,
    sendImages,
    sendVideos,
    getChatBannerDetail,
    banners,
    bannerLoading,
    subscribeToLastMessage,
    subscribeLoading,
    lastMessages,
    readChatMessage
  } = useChatStore();

  const { getChatBadge, chatBadge } = useNotificationStore();

  useEffect(() => {
    if (!getAuthToken()) {
      navigate('/login');
    }
    if (!status || influencers[status].data.length === 0) {
      navigate(`/deal?dealId=${dealId}&status=pending`);
    }
    subscribeToLastMessage(dealContactId, popChatInfluencerToTop);
    getChatBadge();
  }, []);

  useEffect(() => {
    initialize(dealContactId).catch(error => console.log(error));
  }, [dealContactId]);

  useEffect(() => {
    if (!data && !!influencer) {
      setStatus(influencer.status);
      listInfluencers({
        status: influencer.status,
        limit: 10,
        offset: 0,
        dealId,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        filter: 'none'
      });
      getDeal({ dealId });
    }
  }, [influencer]);

  const initialize = async (dealContactId: string) => {
    try {
      setIsLoading(true);
      const int = await getInfluencer({ dealContactId });

      if (!int) {
        console.warn('Influencer not found.');
        return;
      }

      GET(`/lastOnline/${influencer?.account.id}`).then(result => {
        setLastOnline(result.updatedAt);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: remove these x?.y?.img objects
  return (
    <Container>
      <ScrollToTop />
      <WorkModal
        workList={workList}
        resetWorkList={() => setWorkList([])}
        defaultIndex={index}
      />
      <ProfileFullScreenModal
        imageList={imageList}
        resetImage={() => setImageList([])}
        startIndex={startIndex}
      />
      <AcceptInfluModal
        active={acceptModalActive}
        setActive={() => setAcceptModalActive('')}
        username={influencer?.account?.displayName || influencer?.account?.name}
        approveInflu={async () => {
          approve({
            dealContactId: dealContactId,
            influencerName:
              influencer?.account?.displayName ||
              influencer?.account?.name ||
              ''
          });
          const index = influencers.pending.data.findIndex(influencer => {
            return influencer.dealContactId === dealContactId;
          });
          removePendingInflu({ dealContactId });
          if (influencers.pending.data.length !== 0) {
            let newIndex = index;
            if (influencers.pending.data.length === index) newIndex = index - 1;
            const nextDealContactId =
              influencers.pending.data[newIndex].dealContactId;
            navigate(
              `/influencer?dealId=${dealId}&status=${status}&dealContactId=${nextDealContactId}&page=profile`
            );
          } else {
            navigate(`/deal?dealId=${deal.dealId}&status=ongoing`);
          }
        }}
        rejectInflu={async () => {
          reject({ dealContactId: dealContactId });
          const index = influencers.pending.data.findIndex(influencer => {
            return influencer.dealContactId === dealContactId;
          });
          removePendingInflu({ dealContactId });
          if (influencers.pending.data.length !== 0) {
            let newIndex = index;
            if (influencers.pending.data.length === index) newIndex = index - 1;
            const nextDealContactId =
              influencers.pending.data[newIndex].dealContactId;
            navigate(
              `/influencer?dealId=${dealId}&status=${status}&dealContactId=${nextDealContactId}&page=profile`
            );
          } else {
            navigate(`/deal?dealId=${deal.dealId}&status=ongoing`);
          }
        }}
      />
      <ContentContainer>
        <ContentComponent
          page={page}
          setIndex={setIndex}
          setStartIndex={setStartIndex}
          finishWorkModal={finishWorkModal}
          setFinishWorkModal={setFinishWorkModal}
          lastOnline={lastOnline}
          isLoading={isLoading}
          showDealDetail={showDealDetail}
          setShowDealDetail={setShowDealDetail}
          removeToReviewInflu={removeToReviewInflu}
          deal={deal}
          influencer={influencer}
          send={send}
          messages={messages}
          subscribeToChat={subscribeToChat}
          canLoadEarlier={canLoadEarlier}
          dealContactId={dealContactId}
          loading={loading}
          influencers={influencers}
          loadEarlier={loadEarlier}
          toggleShouldUpdate={toggleShouldUpdate}
          shouldUpdate={shouldUpdate}
          sendImages={sendImages}
          sendVideos={sendVideos}
          getChatBannerDetail={getChatBannerDetail}
          banners={banners}
          bannerLoading={bannerLoading}
          readChatMessage={readChatMessage}
          account={account}
          popChatInfluencerToTop={popChatInfluencerToTop}
          setAcceptModalActive={setAcceptModalActive}
          navigate={navigate}
          getDealInfluReview={getDealInfluReview}
          setWorkList={setWorkList}
        />
      </ContentContainer>
    </Container>
  );
};

export default observer(InfluencerManagement);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1200px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  min-width: 1200px;
  width: 100%;
`;

const DealContainer = styled.div`
  position: relative;
  flex-direction: column;
  margin: 94px 0px 0px 73px;
`;
