import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import RLoading from '../../components/atoms/RLoading';
import ScrollToTop from '../../components/atoms/ScrollToTop';
import RejectInfluModal from '../../components/molecules/CampaignManagement/RejectInfluModal';
import UseDraftModal from '../../components/molecules/CreateCampaign/UseDraftModal';
import CampaignInfoDrawer from '../../components/organisms/Campaign/CampaingInfoDrawer';
import CampaignManagementBoard from '../../components/organisms/CampaignManagement/CampaignManagementBoard';
import {
  useAccountStore,
  useChatStore,
  useCreateDealStore,
  useDealStore,
  useInfluencerStore,
  useNotificationStore,
  useWebSocketStore
} from '../../stores/initialize/Context';
import { StatusType } from '../../types/deal';
import { useQuery } from '../../utils';

const sortByOptions = [
  { label: 'คำขอใหม่ - เก่า', key: 'createdAt' },
  { label: 'Followers มาก - น้อย', key: 'influencer.followers_count' },
  {
    label: 'Active Followers % มาก - น้อย',
    key: 'influencer.activeFollowers'
  },
  {
    label: 'Engagement มาก - น้อย',
    key: 'influencer.recentMediaEngagement'
  }
];

export type PageStatus = 'detail' | 'edit' | 'recommend' | StatusType;

function CampaignManagement() {
  const [page, setPage] = useState<number>(1);
  const { deal, getDeal, loading, cancelInflu } = useDealStore();
  const {
    listInfluencers,
    influencers,
    loading: { influencers: loadingInfluencers },
    clearInflueners
  } = useInfluencerStore();
  const { account } = useAccountStore();
  const { brandId } = account;
  const { getNotifications, getChatBadge, chatBadge, chatBadgeLoading } =
    useNotificationStore();
  const navigate = useNavigate();
  let query = useQuery();

  // can only get string not object
  const dealId = query.get('dealId') as string;
  const drawerStatus = localStorage.getItem('drawerStatus') || '';

  const status = query.get('status') as PageStatus;

  const [selectedBar, setSelectedBar] = useState<PageStatus>(status);
  const [nextOffset, setNextOffset] = useState(0);
  const [filter, setFilter] = useState('none');
  const [sortByTitle, setSortByTitle] = useState<string>(
    sortByOptions[0].label
  );
  const [sortBy, setSortBy] = useState<string>(sortByOptions[0].key);
  const [sortOrder] = useState<string>('desc');
  const [reason, setReason] = useState<string>('');
  const [rejectActive, setRejectActive] = useState<boolean>(false);
  const [dealContactId, setDealContactId] = useState<string>('');
  const [draftModalActive, setDraftModalActive] = useState<string>('');
  const [dealDetailAcitve, setDealDetailAcitve] = useState<boolean>(false);
  const [reconnect, setReconnect] = useState<boolean>(true);
  const { isDraftAvailable, clearDraft } = useCreateDealStore();
  const { socket, connectWebsocket } = useWebSocketStore();
  const { shouldUpdate, toggleShouldUpdate, setShouldUpdatetoTrue } =
    useChatStore();
  const statusList: StatusType[] = ['pending', 'ongoing', 'check', 'done'];

  const onReject = () => {
    // TODO: Remove this if possible
    if (status !== 'edit' && status !== 'detail' && status !== 'recommend') {
      cancelInflu({ dealContactId, reason, status });
      setRejectActive(false);
    }
  };

  useEffect(() => {
    connectWebsocket({ channel: channelName });
    return () => socket[channelName]?.close();
  }, []);

  useEffect(() => {
    getDeal({ dealId });
    clearInflueners();
  }, [dealId]);

  const channelName = 'dealinflu-' + dealId;

  useEffect(() => {
    setSelectedBar(status);
  }, [status]);

  const initialize = (
    isRefresh = false,
    customSort = null,
    customFilter = null
  ) => {
    //close it for now. Waiting for data/metadata format on list influencer

    // if (nextOffset === 0 && !isRefresh) {
    //   return;
    // }
    // setNextOffset(0);
    statusList.map((status: StatusType) => {
      listInfluencers({
        status,
        limit: 10,
        offset: 0,
        dealId,
        sortBy: customSort ?? sortBy,
        sortOrder: sortOrder,
        filter: customFilter ?? filter
      }).catch((error: any) => console.log(error));
    });
    getChatBadge();
  };

  useEffect(initialize, [dealId, filter, sortBy]);

  useEffect(() => {
    if (socket[channelName]) {
      socket[channelName].onmessage = event => {
        const StatusType = JSON.parse(event.data);
        getChatBadge();
        if (Object(StatusType).hasOwnProperty('type')) {
          if (StatusType.type === 'chat') {
            getDeal({ dealId, isChatUpdate: true });
            if (status !== 'detail' && status !== 'edit') {
              setShouldUpdatetoTrue();
            }
          } else {
            listInfluencers({
              status: StatusType.type,
              limit: 10,
              offset: 0,
              dealId,
              sortBy: sortBy,
              sortOrder: sortOrder,
              filter: filter
            }).catch((error: any) => console.log(error));
          }
          getNotifications({ offset: 0, limit: 10 });
        }
      };
      socket[channelName].onclose = () => {
        if (reconnect) {
          connectWebsocket({ channel: channelName });
          return () => {
            setReconnect(false);
            socket[channelName]?.close();
          };
        }
      };
    }
  }, [socket[channelName]]);

  useEffect(() => {
    if (
      selectedBar === 'detail' ||
      selectedBar === 'edit' ||
      selectedBar === 'recommend'
    )
      return;
    listInfluencers({
      status: selectedBar,
      limit: 10,
      offset: 0,
      dealId,
      sortBy: sortBy,
      sortOrder: sortOrder,
      filter: filter
    }).catch((error: any) => console.log(error));
  }, [selectedBar, dealId]);

  const renderContent = () => {
    if (loading.deal || !deal) {
      return <RLoading containerStyle={{ marginTop: '150px' }} />;
    } else {
      return (
        <CampaignManagementBoard
          navigate={navigate}
          deal={deal}
          influencers={influencers}
          selectedBar={selectedBar}
          setRejectActive={setRejectActive}
          setDealContactId={setDealContactId}
          brandId={brandId}
          setDealDetailActive={setDealDetailAcitve}
          shouldUpdate={shouldUpdate}
          toggleShouldUpdate={toggleShouldUpdate}
          chatBadge={chatBadge}
          loadingInfluencers={loadingInfluencers}
        />
      );
    }
  };

  return (
    <Container>
      <ScrollToTop />
      <UseDraftModal
        modalVisible={draftModalActive}
        setModalVisible={setDraftModalActive}
        navigate={navigate}
        clearDraft={clearDraft}
      />
      <ContentContainer>{renderContent()}</ContentContainer>
      <RejectInfluModal
        active={rejectActive}
        setActive={() => setRejectActive(!rejectActive)}
        reason={reason}
        setReason={setReason}
        onReject={onReject}
      />
    </Container>
  );
}

export default observer(CampaignManagement);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1200px;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
  min-width: 1200px;
`;
