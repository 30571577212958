import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ITierType } from '../../../types/createDeal';
import { PlatformType } from '../../../types/deal';
import { TierWorkData } from '../../../types/work';
import {
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import TierSummaryComponent from './TierSummaryComponent';

interface Props {
  tierList: ITierType;
  tierInsight: {
    nano: TierWorkData;
    micro: TierWorkData;
    mid: TierWorkData;
    macro: TierWorkData;
  };
  platform: PlatformType;
}

const TierSummary = (props: Props) => {
  const { tierList, tierInsight, platform } = props;

  return (
    <Container>
      <HeaderContainer>
        <BoldH6Text>Tier Summary</BoldH6Text>
        <RXIcon name="Info" color={COLOR.Gray_M} size={20} />
      </HeaderContainer>
      <ContentContainer>
        <TierSummaryComponent
          tier="nano"
          current={tierInsight.nano.uniqueInfluencer.size}
          insight={tierInsight.nano}
          max={tierList.nano.value}
          platform={platform}
        />
        <TierSummaryComponent
          tier="micro"
          current={tierInsight.micro.uniqueInfluencer.size}
          insight={tierInsight.micro}
          max={tierList.micro.value}
          platform={platform}
        />
        <TierSummaryComponent
          tier="mid"
          current={tierInsight.mid.uniqueInfluencer.size}
          insight={tierInsight.mid}
          max={tierList.mid.value}
          platform={platform}
        />
        <TierSummaryComponent
          tier="macro"
          current={tierInsight.macro.uniqueInfluencer.size}
          insight={tierInsight.macro}
          max={tierList.macro.value}
          platform={platform}
        />
      </ContentContainer>
    </Container>
  );
};

export default TierSummary;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${COLOR.Gray_300};
`;

const HeaderContainer = styled(SpaceBetweenRowContainer)`
  padding: 24px;
  border-bottom: 0.6px solid ${COLOR.Gray_300};
`;

const ContentContainer = styled(RowContainer)`
  padding: 24px;
  gap: 12px;
  flex-wrap: wrap;
  width: 100%;
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;
