import { observer } from 'mobx-react';
import { useState } from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { PostMedias, ScopeOfWork } from '../../../../class/ScopeOfWork';
import { ANDCOLOR } from '../../../../fonts/andColor';
import { ANDTEXT } from '../../../../fonts/andText';
import { pageIndex } from '../../../../pages/NewCreateCampaign/NewCreateCampaignPage';
import {
  ITierType,
  influencerCaption,
  tierType
} from '../../../../types/createDeal';
import { PlatformType } from '../../../../types/deal';
import { numberWithCommas, toCapitalize } from '../../../../utils';
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../../utils/styling/general';
import { ImageProp } from '../../../organisms/CreateCampaign/ImageSelector';
import ImageList from '../../ImageList';
import PostMediaList from './PostMediaList';
import { TEXT } from '../../../../fonts/text';

interface Props {
  setPage?: (index: pageIndex) => void;
  postMedia: PostMedias;
  caption: influencerCaption;
  hashtags: string[];
  examples: ImageProp[];
  documents: string[];
  influencersTier: ITierType;
  isValue: boolean;
  value: number;
  isCash: boolean;
  benefits: string;
  platform: PlatformType;
  mustReturnProduct: boolean;
  isNegotiateCash: boolean;
  width?: number;
  haveEdit?: boolean;
}

function DealCardScopeOfWork(props: Props) {
  const {
    setPage,
    postMedia,
    caption,
    hashtags,
    examples,
    documents,
    influencersTier,
    value,
    benefits,
    isValue,
    isCash,
    platform,
    mustReturnProduct,
    isNegotiateCash,
    haveEdit = true
  } = props;

  const [active, setActive] = useState<boolean>(true);

  let scopeOfWork = new ScopeOfWork(postMedia);

  const rewardCount = () => {
    let count = 0;
    if (value) count++;
    if (
      influencersTier.nano.cash > 0 ||
      influencersTier.micro.cash > 0 ||
      influencersTier.mid.cash > 0 ||
      influencersTier.macro.cash > 0
    )
      count++;
    return count;
  };

  const BriefEachTier = ({
    type,
    index,
    value
  }: {
    type: tierType;
    index: number;
    value: string;
  }) => {
    return (
      <FullWidthRowContainer style={{ paddingLeft: 4 }}>
        <BoldHeader7Text>{index + '. ' + toCapitalize(type)}</BoldHeader7Text>
        <Body2Text>{value}</Body2Text>
      </FullWidthRowContainer>
    );
  };

  const totalCash =
    influencersTier.nano.cash * influencersTier.nano.value +
    influencersTier.micro.cash * influencersTier.micro.value +
    influencersTier.mid.cash * influencersTier.mid.value +
    influencersTier.macro.cash * influencersTier.macro.value;

  const totalInfluencer =
    influencersTier.nano.value +
    influencersTier.micro.value +
    influencersTier.macro.value +
    influencersTier.mid.value;

  const totalCashValue = value * totalInfluencer + totalCash;

  const BriefComponent = () => {
    return (
      <FullWidthRowContainer>
        <BoldHeader7Text>บรีฟงาน </BoldHeader7Text>
        <Body2Text style={{ width: '65%' }}>{caption.nano}</Body2Text>
      </FullWidthRowContainer>
    );
  };

  const HashtagComponent = () => {
    let components: string = '';
    hashtags.map(ht => (components += '#' + ht + ' '));
    return <Body2Text style={{ width: 408 }}>{components}</Body2Text>;
  };

  const ProductComponent = () => {
    if (isValue) {
      return (
        <>
          <FullWidthRowContainer>
            <ProductCashText>1. สินค้า / บริการ / โปรโมชัน</ProductCashText>
            <ProductCashText>
              {numberWithCommas(value * totalInfluencer)} บาท
            </ProductCashText>
          </FullWidthRowContainer>
          <MustReturnProductComponent />
          <FullWidthRowContainer>
            <BenefitsText>{benefits || 'จำนวนสินค้า'}</BenefitsText>
            <B2Text>{'จำนวน ' + totalInfluencer + ' ชิ้น'}</B2Text>
          </FullWidthRowContainer>
        </>
      );
    }
    return <></>;
  };

  const MustReturnProductComponent = () => {
    if (mustReturnProduct)
      return (
        <IconTextContainer>
          <RXIcon name="Check" size={16} />
          <Body2Text>ต้องการสินค้าคืนหลังจบแคมเปญ</Body2Text>
        </IconTextContainer>
      );
    return <></>;
  };

  const CashComponent = () => {
    if (isCash) {
      const index = isValue ? 2 : 1;
      return (
        <FullWidthRowContainer>
          <ProductCashText>{index}. ค่าตอบแทน</ProductCashText>
          <ProductCashText>{numberWithCommas(totalCash)} บาท</ProductCashText>
        </FullWidthRowContainer>
      );
    }
    return <></>;
  };

  const CashEachTier = ({
    type,
    cash,
    influCount
  }: {
    type: tierType;
    cash: number;
    influCount: number;
  }) => {
    if (influCount > 0)
      return (
        <FullWidthRowContainer style={{ paddingLeft: 4 }}>
          <CashText>
            {'・' + toCapitalize(type) + ' จำนวน ' + influCount + ' คน'}
          </CashText>
          <Body2Text>{numberWithCommas(cash * influCount)} บาท</Body2Text>
        </FullWidthRowContainer>
      );
    return <></>;
  };

  const IsNegotiateComponent = () => {
    if (isNegotiateCash)
      return (
        <IconTextContainer>
          <RXIcon name="Check" size={16} />
          <Body2Text>ต่อรองค่าตอบแทนได้</Body2Text>
        </IconTextContainer>
      );
    return <></>;
  };

  const PlatfromIcon = () => {
    switch (platform) {
      case 'INSTAGRAM':
        return (
          <Image
            src={require('../../../../assets/images/PlatformLogos/Instagram.png')}
          />
        );
      case 'TIKTOK':
        return (
          <Image
            src={require('../../../../assets/images/PlatformLogos/Tiktok.png')}
          />
        );
      case 'LEMON':
        return (
          <Image
            src={require('../../../../assets/images/PlatformLogos/Lemon.png')}
          />
        );
      case 'FACEBOOK':
        return (
          <Image
            src={require('../../../../assets/images/PlatformLogos/Facebook.png')}
          />
        );
    }
    return <></>;
  };

  const ContentComponent = () => {
    if (active)
      return (
        <ContentContainer>
          <PlatformContainer>
            <PlatformIconContainer>
              <PlatfromIcon />
            </PlatformIconContainer>
            <WhiteBoldButtonSmallText>
              {toCapitalize(platform)}
            </WhiteBoldButtonSmallText>
          </PlatformContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>รูปแบบโพสต์ ({postMedia.length})</BoldHeader7Text>
            <DetailContainer>
              <PostMediaList platform={platform} scopeOfWork={scopeOfWork} />
            </DetailContainer>
          </FullWidthRowContainer>
          <BriefComponent />
          <FullWidthRowContainer>
            <BoldHeader7Text>Hashtag ({hashtags.length})</BoldHeader7Text>
            <DetailContainer>
              <HashtagComponent />
            </DetailContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>ตัวอย่างรูป ({examples.length})</BoldHeader7Text>
            <DetailContainer>
              <ImageList images={examples} imageStyle={{ marginTop: 0 }} />
            </DetailContainer>
          </FullWidthRowContainer>
          {/* <FullWidthRowContainer >
            <BoldHeader7Text>
              ไฟล์เพิ่มเติม ({documents.length})
            </BoldHeader7Text>
            <DetailContainer></DetailContainer>
          </FullWidthRowContainer> */}
          <FullWidthRowContainer>
            <BoldHeader7Text>รางวัล ({rewardCount()})</BoldHeader7Text>
            <DetailContainer>
              <ProductComponent />
              <CashComponent />
              {/* Remove it for now. Have problem with some brand when deal is negotiable */}
              {/* <IsNegotiateComponent /> */}
              <CashEachTier
                type="nano"
                cash={influencersTier.nano.cash}
                influCount={influencersTier.nano.value}
              />
              <CashEachTier
                type="micro"
                cash={influencersTier.micro.cash}
                influCount={influencersTier.micro.value}
              />
              <CashEachTier
                type="mid"
                cash={influencersTier.mid.cash}
                influCount={influencersTier.mid.value}
              />
              <CashEachTier
                type="macro"
                cash={influencersTier.macro.cash}
                influCount={influencersTier.macro.value}
              />
            </DetailContainer>
          </FullWidthRowContainer>
          <SummaryComponent />
        </ContentContainer>
      );
    return <></>;
  };

  const SummaryComponent = () => {
    if (isCash || isValue) {
      return (
        <TotalValueContainer>
          <WhiteBoldHeader7Text>มูลค่ารางวัลทั้งหมด</WhiteBoldHeader7Text>
          <WhiteBoldHeader7Text>
            {numberWithCommas(totalCashValue)} บาท
          </WhiteBoldHeader7Text>
        </TotalValueContainer>
      );
    }
    return <></>;
  };

  const EditComponent = () => {
    if (haveEdit)
      return (
        <EditContainer
          onClick={() => {
            if (!!setPage) setPage(2);
          }}
        >
          <RXIcon name="Edit" size={16} color={ANDCOLOR.BLUE} />
          <ButtonSmallText>แก้ไข</ButtonSmallText>
        </EditContainer>
      );
    return <></>;
  };

  return (
    <Container>
      <TitleContainer>
        <BoldHeader6Text>ขั้นตอนที่ 2 : ขอบเขตของงาน</BoldHeader6Text>
        <RowContainer>
          <EditComponent />
          <IconContainer onClick={() => setActive(!active)}>
            <RXIcon
              name={active ? 'ArrowUp' : 'ArrowDown'}
              color={ANDCOLOR.BLUE}
            />
          </IconContainer>
        </RowContainer>
      </TitleContainer>
      <ContentComponent />
    </Container>
  );
}

export default observer(DealCardScopeOfWork);

const Container = styled.div`
  width: 100%;
  margin-top: 72px;
`;

const ContentContainer = styled(ColumnContainer)`
  align-items: flex-start;
  gap: 16px;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  align-items: flex-start;
  width: 100%;
`;

const HalfWidthRowContainer = styled(SpaceBetweenRowContainer)`
  align-items: flex-start;
  width: 50%;
`;

const DetailContainer = styled(ColumnContainer)`
  align-items: flex-start;
  width: 65%;
  gap: 4px;
`;

const TitleContainer = styled(SpaceBetweenRowContainer)`
  border-bottom: 1px solid ${ANDCOLOR.BLUE};
  padding-bottom: 16px;
  margin-bottom: 24px;
`;

const EditContainer = styled(RowContainer)`
  :hover {
    cursor: pointer;
  }
`;

const PlatformContainer = styled(SpaceBetweenRowContainer)`
  padding: 8px;
  background-color: ${ANDCOLOR.BLUE};
  gap: 4px;
  border-radius: 26px;
`;

const PlatformIconContainer = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  background-color: ${ANDCOLOR.WHITE};
`;

const IconContainer = styled.div`
  margin-left: 16px;
  :hover {
    cursor: pointer;
  }
`;

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TotalValueContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: ${ANDCOLOR.BLUE};
  margin-top: 36px;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const BoldHeader6Text = styled(ANDTEXT.header6BOLD)`
  color: ${ANDCOLOR.BLUE};
`;

const CashText = styled(TEXT.B2_Reg)``;

const ProductCashText = styled(ANDTEXT.header7BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  min-width: 300px;
`;

const WhiteBoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  color: ${ANDCOLOR.WHITE};
`;

const WhiteBoldButtonSmallText = styled(ANDTEXT.buttonSmallBOLD)`
  color: ${ANDCOLOR.WHITE};
`;

const Body2Text = styled(ANDTEXT.body2)`
  max-width: 65%;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const B2Text = styled(TEXT.B2_Reg)`
  margin-left: 21px;
  margin-top: 4px;
`;

const BenefitsText = styled(TEXT.B2_Reg)`
  margin-left: 21px;
  margin-top: 4px;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ButtonSmallText = styled(ANDTEXT.buttonSmall)`
  margin-left: 4px;
  color: ${ANDCOLOR.BLUE};
`;
