import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { tierType } from '../../../types/createDeal';
import { PlatformType } from '../../../types/deal';
import { TierWorkData } from '../../../types/work';
import { toCapitalize } from '../../../utils';
import {
  ColumnContainer,
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import TierSummaryDetail from './TierSummaryDetail';

interface TierComponentProps {
  tier: tierType;
  current: number;
  max: number;
  insight: TierWorkData;
  platform: PlatformType;
}

const TierSummaryComponent = (props: TierComponentProps) => {
  const { insight, max, current, tier, platform } = props;
  const { totalEngagement, totalReach, totalImpressions, cpe, cpr, cpm } =
    insight;

  const isTiktok = platform === 'TIKTOK';
  const impressionText = isTiktok ? 'View (CPM)' : 'Impression (CPM)';

  if (max === 0) return <></>;

  return (
    <TierContainer>
      <FullWidthSpaceRow>
        <TierTagContainer>
          <BoldBlue700B3Text>{toCapitalize(tier)}</BoldBlue700B3Text>
        </TierTagContainer>
        <FourGapRowContainer>
          <BoldH6Text>{current}</BoldH6Text>
          <BoldGray400H7Text>{`/ ${max}`}</BoldGray400H7Text>
        </FourGapRowContainer>
      </FullWidthSpaceRow>
      <GapRowContainer>
        <TierSummaryDetail
          title={'Engagement (CPE)'}
          value={totalEngagement}
          rate={cpe}
        />
        {platform !== 'TIKTOK' && (
          <TierSummaryDetail
            title={'Reach (CPR)'}
            value={totalReach}
            rate={cpr}
          />
        )}
        <TierSummaryDetail
          title={impressionText}
          value={totalImpressions}
          rate={cpm}
        />
      </GapRowContainer>
    </TierContainer>
  );
};

export default TierSummaryComponent;

const TierContainer = styled(ColumnContainer)`
  width: 49.4%;
  height: 187px;
  padding: 24px;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 12px;
  border: 0.6px solid ${COLOR.Gray_300};
`;

const TierDetailContainer = styled(FlexStartColumnContainer)`
  width: 33%;
`;
const FullWidthSpaceRow = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const FourGapRowContainer = styled(RowContainer)`
  gap: 4px;
`;

const GapRowContainer = styled(RowContainer)`
  width: 100%;
  gap: 12px;
`;

const TierTagContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: ${COLOR.Blue_50};
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;

const BoldBlue700B3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.Blue_700};
`;

const BoldGray400H7Text = styled(TEXT.H7_Bold)`
  color: ${COLOR.Gray_400};
`;
