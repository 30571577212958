import { useState } from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../../fonts/color';
import { TEXT } from '../../../../fonts/text';
import { RowContainer } from '../../../../utils/styling/general';
import RTextarea from '../../../atoms/RTextarea';

interface Props {
  hashtags: string[];
  setHashtags: (hashtags: string[]) => void;
}

const Hashtag = (props: Props) => {
  const { hashtags, setHashtags } = props;

  const [keyboardActive, setKeyboardActive] = useState<boolean>(false);
  let defaultHashtag = '';
  hashtags.map(hashtag => {
    defaultHashtag = defaultHashtag + '#' + hashtag;
  });

  const removeHashtag = (hashtag: string) => {
    let tempHashtags: string[] = [...hashtags];
    tempHashtags = tempHashtags.filter(ht => ht !== hashtag);
    setHashtags(tempHashtags);
  };

  const Hashtags = () => {
    const hts: JSX.Element[] = [];
    hashtags.forEach(ht =>
      hts.push(
        <SingleHashtagContainer>
          <HashtagText># {ht}</HashtagText>
          <IconContainer onClick={() => removeHashtag(ht)}>
            <RXIcon name="Close" color={COLOR.Blue_400} size={16} />
          </IconContainer>
        </SingleHashtagContainer>
      )
    );
    return <>{hts}</>;
  };

  const HashtagComponent = () => {
    if (keyboardActive || hashtags.length === 0)
      return (
        <RTextarea
          inputProp={{
            defaultValue: defaultHashtag,
            placeholder:
              '#AnotherDotThailand1 #AnotherDotThailand2 #AnotherDotThailand3',
            style: {
              display: 'flex',
              width: 568,
              height: 180,
              paddingTop: 16,
              paddingBottom: 16,
              paddingRight: 24,
              paddingLeft: 24,
              gap: 10,
              borderRadius: 16,
              border: `1px solid ${COLOR.Gray_400}`,
              background: `${COLOR.WHITE}`
            },
            onBlur: e => {
              const hashtagString = e.target.value;
              const hashtags = hashtagString.split('#');
              const tempHashtags: string[] = [];
              hashtags.map((hashtag, index) => {
                if (index === 0) {
                  return;
                }
                const text = hashtag.replace(/\n/g, ' ');
                const spaceIndex = text.indexOf(' ');
                const sliceIndex = spaceIndex === -1 ? text.length : spaceIndex;
                tempHashtags.push(hashtag.slice(0, sliceIndex));
                return;
              });
              setHashtags(tempHashtags);
              setKeyboardActive(false);
            }
          }}
          containerStyle={{ marginTop: '12px' }}
        />
      );
    return (
      <HashtagContainer onClick={() => setKeyboardActive(true)}>
        <Hashtags />
      </HashtagContainer>
    );
  };

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>Hashtag</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <Body3Text>Hashtag ประจำแคมเปญสำหรับ Influencer ใส่ในโพสต์</Body3Text>
        </GapRowContainer>
        <HashtagComponent />
      </ContentContainer>
    </Container>
  );
};

export default Hashtag;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const HashtagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  width: 568px;
  height: 180px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 24px;
  padding-left: 24px;
  margin-top: 34px;
  margin-bottom: 28px;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid ${COLOR.Gray_400};
  background: ${COLOR.WHITE};
`;

const SingleHashtagContainer = styled.div`
  display: flex;
  height: 32px;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.Blue_50};
  gap: 10px;
  border-radius: 24px;
`;

const IconContainer = styled.div`
  margin-top: 4px;
  :hover {
    cursor: pointer;
  }
`;
const HashtagText = styled(TEXT.B3_Reg)`
  color: ${COLOR.Blue_400};
`;

const BoldHeader7Text = styled(TEXT.H7_Bold)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_D};
`;
