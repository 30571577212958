import axios from 'axios';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { scopeOfWorkType } from '../../../class/ScopeOfWork';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { PostTypes, Work } from '../../../types/work';
import {
  PLACEHOLDER_IMAGE,
  PLACEHOLDER_REELS,
  toDateDue,
  toDecimalNumber
} from '../../../utils';
import { RowContainer } from '../../../utils/styling/general';
import RModal from '../../atoms/RModal';

interface Props {
  workList: Work[];
  resetWorkList: () => void;
  defaultIndex: number;
}
interface DownloadProps {
  disable: boolean;
}

// TODO: make render Information an export in util
export const Information = ({
  value,
  description,
  style,
  containerStyle = {},
  iconName
}: {
  value: string | number;
  description: string;
  style: CSSProperties;
  containerStyle?: CSSProperties;
  iconName: IconName;
}) => {
  return (
    <BoxContainer style={containerStyle}>
      <RXIcon name={iconName} size={20} />
      <InformationContainer style={style}>
        <BoldB3Text>
          {toDecimalNumber({ number: parseInt(value.toString()) })}
        </BoldB3Text>
        <B4Text>{description}</B4Text>
      </InformationContainer>
    </BoxContainer>
  );
};

function WorkModal(props: Props) {
  const { workList, resetWorkList, defaultIndex } = props;
  const [index, setIndex] = useState<number>(defaultIndex);

  useEffect(() => {
    if (!!workList.length) setIndex(defaultIndex);
  }, [workList.length]);

  const getHeaderText = (postType: scopeOfWorkType) => {
    switch (postType) {
      case 'FB_individual_post':
      case 'IG_individual_post':
        return 'Single Post';
      case 'FB_album_post':
      case 'IG_album_post':
        return 'Multi Post';
      case 'FB_reels':
      case 'IG_reels':
        return 'Reels';
      case 'IG_story':
        return 'Story';
      case 'IG_tv':
        return 'TV';
      case 'TK_video':
        return 'TikTok';
    }
  };

  const determineIcon = (postType: scopeOfWorkType) => {
    switch (postType) {
      case 'FB_individual_post':
      case 'IG_individual_post':
        return 'Image';
      case 'FB_album_post':
      case 'IG_album_post':
        return 'Album';
      case 'FB_reels':
      case 'IG_reels':
        return 'Reels';
      case 'IG_story':
        return 'StoryCircle';
      case 'IG_tv':
        return 'TV';
      case 'TK_video':
        return 'Tiktok';
      default:
        return 'Image';
    }
  };

  const handleDownloadClick = async (downloadUrl: string, name: string) => {
    const response = await fetch(downloadUrl);

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const changeImage = (direction: 1 | -1) => {
    if (direction === 1) {
      if (index + 1 > workList.length - 1) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    } else {
      if (index - 1 < 0) {
        setIndex(workList.length - 1);
      } else {
        setIndex(index - 1);
      }
    }
  };

  const WorkIcon = ({ postType }: { postType: scopeOfWorkType }) => {
    if (postType === 'TK_video') {
      return (
        <IconContainer background={COLOR.BLACK}>
          <RXIcon name="Tiktok" size={18} color={COLOR.WHITE} />
        </IconContainer>
      );
    } else if (postType.startsWith('FB')) {
      return (
        <IconContainer background={COLOR.WHITE}>
          <RXIcon name="FacebookCircle" size={27} color={COLOR.Blue_700} />
        </IconContainer>
      );
    } else {
      return (
        <IconContainer background="linear-gradient(225deg, #4F5BD5 0.62%, #962FBF 23.93%, #D62976 48.93%, #FA7E1E 70.81%, #FEDA75 96.33%);">
          <RXIcon name="Instagram" size={18} color={COLOR.WHITE} />
        </IconContainer>
      );
    }
  };

  const renderContent = () => {
    if (workList.length === 0) {
      return <></>;
    } else {
      const getUri = (work: Work, downloadable?: boolean) => {
        if (!work?.media_type) return PLACEHOLDER_IMAGE;
        if (work.media_type === 'CAROUSEL_ALBUM') return work.media_url;
        if (work.media_type === 'IMAGE')
          return work.media_url || PLACEHOLDER_IMAGE;
        if (!work.thumbnail_url || work.thumbnail_url === null)
          return PLACEHOLDER_REELS;
        if (work.media_type === 'VIDEO') {
          return downloadable ? work.media_url : work.thumbnail_url;
        }
        return work.media_url || work.thumbnail_url || PLACEHOLDER_IMAGE;
      };

      const work = workList[index];

      const isShared = work?.postType.includes('IG') ? 'Saved' : 'Shared';

      const InformationComponent = () => {
        if (work?.postType === 'IG_story') {
          return (
            <DetailContainer>
              <>
                <BoldH7Text>Post Interaction</BoldH7Text>
                <StatsContainer>
                  <Information
                    value={work?.like_count || 0}
                    description={'Likes'}
                    style={{ width: '116px', marginLeft: '10px' }}
                    iconName="HeartFill"
                  />
                  <Information
                    value={work?.replies || 0}
                    description={'Replies'}
                    style={{ width: '116px', marginLeft: '10px' }}
                    iconName="PaperSendFill"
                  />
                </StatsContainer>
                <InsightContainer>
                  <BoldH7Text>Insight</BoldH7Text>
                </InsightContainer>
                <InsightContainer style={{ marginTop: '8px' }}>
                  <B4Text>Reach</B4Text>
                  <B4Text>{Math.round(work?.reach || 0)}</B4Text>
                </InsightContainer>
                <InsightContainer>
                  <B4Text>Impression</B4Text>
                  <B4Text>{Math.round(work?.impressions || 0)}</B4Text>
                </InsightContainer>
                <InsightContainer>
                  <B4Text>Tab Forward</B4Text>
                  <B4Text>{work?.taps_forward || 0}</B4Text>
                </InsightContainer>
                <InsightContainer>
                  <B4Text>Tab Back</B4Text>
                  <B4Text>{work?.taps_back || 0}</B4Text>
                </InsightContainer>
                <InsightContainer>
                  <B4Text>Exits</B4Text>
                  <B4Text>{work?.exits || 0}</B4Text>
                </InsightContainer>
              </>
              <RowContainer style={{ marginTop: '24px' }}>
                <RXIcon name="Clock" />
                <B5Text style={{ marginLeft: '10px' }}>
                  Last Update : {toDateDue(work?.updatedAt)}
                </B5Text>
              </RowContainer>
            </DetailContainer>
          );
        } else {
          return (
            <DetailContainer>
              <>
                <BoldH7Text>Post Interaction</BoldH7Text>
                <StatsContainer>
                  <Information
                    value={work?.like_count || 0}
                    description={'Likes'}
                    style={{ width: '116px', marginLeft: '10px' }}
                    iconName="HeartFill"
                  />
                  <Information
                    value={work?.comments_count || 0}
                    description={'Comments'}
                    style={{ width: '116px', marginLeft: '10px' }}
                    iconName="ChatFill"
                  />
                  <Information
                    value={work?.saved || 0}
                    description={isShared}
                    style={{ width: '116px', marginLeft: '10px' }}
                    iconName="ShareFill"
                  />
                </StatsContainer>
                <InsightContainer>
                  <BoldH7Text>Insight</BoldH7Text>
                </InsightContainer>
                <InsightContainer style={{ marginTop: '8px' }}>
                  <B4Text>Reach</B4Text>
                  <B4Text>{work?.reach || 0}</B4Text>
                </InsightContainer>
                <InsightContainer>
                  <B4Text>Impression</B4Text>
                  <B4Text>{work?.impressions || 0}</B4Text>
                </InsightContainer>
                <InsightContainer>
                  <B4Text>Engagement</B4Text>
                  <B4Text>{work?.engagement || 0}</B4Text>
                </InsightContainer>
              </>
              <RowContainer style={{ marginTop: '24px' }}>
                <RXIcon name="Clock" />
                <B5Text style={{ marginTop: '2px', marginLeft: '10px' }}>
                  Last Update : {toDateDue(work?.updatedAt)}
                </B5Text>
              </RowContainer>
            </DetailContainer>
          );
        }
      };

      return (
        <ContentContainer>
          <ImageSideContainer>
            <ImageContainer src={getUri(work)} />
            <ImageCaptionContainer>
              <B4Text>{work?.caption}</B4Text>
            </ImageCaptionContainer>
          </ImageSideContainer>
          <DetailSideContainer>
            <DetailHeaderContainer>
              <RowContainer>
                <RXIcon name={determineIcon(work?.postType)} />
                <BoldH5Text>{getHeaderText(work?.postType)}</BoldH5Text>
              </RowContainer>
            </DetailHeaderContainer>
            <InformationComponent />
            <BottomButtonsContainer>
              <NavigateButtonContainer href={work?.permalink} target="_blank">
                <BlueBoldBu1Text
                  style={{ marginTop: '4px', marginRight: '10px' }}
                >
                  ไปที่
                </BlueBoldBu1Text>
                <WorkIcon postType={work?.postType} />
              </NavigateButtonContainer>
              <DownloadButtonContainer
                onClick={e => {
                  if (work?.media_type === 'VIDEO') {
                    window.open(getUri(work, true));
                  } else {
                    handleDownloadClick(
                      getUri(work, true),
                      work?.caption || 'Pickle Work'
                    );
                  }
                }}
                disable={work?.postType === 'TK_video'}
              >
                <WhiteBoldBu1Text
                  disable={work?.postType === 'TK_video'}
                  style={{ marginRight: '10px' }}
                >
                  ดาวน์โหลด
                </WhiteBoldBu1Text>
                <RXIcon
                  name="Download"
                  color={
                    work?.postType === 'TK_video' ? COLOR.Gray_300 : COLOR.WHITE
                  }
                />
              </DownloadButtonContainer>
            </BottomButtonsContainer>
          </DetailSideContainer>
        </ContentContainer>
      );
    }
  };

  return (
    <RModal active={workList.length !== 0} setActive={resetWorkList}>
      <Container>
        <SideButtonsContainer
          onClick={() => (index !== 0 ? changeImage(-1) : resetWorkList)}
          active={index !== 0}
        >
          <RXIcon name="ArrowShortLeft" size={32} />
        </SideButtonsContainer>
        {renderContent()}
        <SideButtonsContainer
          onClick={() =>
            index !== workList.length - 1 ? changeImage(1) : resetWorkList
          }
          active={index !== workList.length - 1}
        >
          <RXIcon name="ArrowShortRight" size={32} />
        </SideButtonsContainer>
        <CloseContainer onClick={resetWorkList}>
          <RXIcon name="Close" color={COLOR.WHITE} />
        </CloseContainer>
      </Container>
    </RModal>
  );
}

export default WorkModal;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 1032px;
  height: 576px;
  padding: 40px 72px;
  background: ${COLOR.WHITE};
  border-radius: 16px;
`;

const ImageSideContainer = styled.div`
  width: 427px;
  margin-right: 32px;
`;

const ImageContainer = styled.img`
  width: 427px;
  height: 400px;
  background: ${COLOR.Gray_200};
  border-radius: 16px;
  object-fit: cover;
`;

const ImageCaptionContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DetailSideContainer = styled.div`
  width: 427px;
`;

const DetailHeaderContainer = styled.div`
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 324px;
  padding: 24px 32px;
  margin-top: 16px;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 16px;
`;

const InsightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

const BoxContainer = styled.div`
  display: flex;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  margin-top: 16px;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const NavigateButtonContainer = styled.a`
  display: flex;
  width: 120px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLOR.Blue_700};
  border-radius: 25px;
  text-decoration: none;

  :hover {
    background: ${COLOR.Blue_50};
  }
`;

const DownloadButtonContainer = styled.a<DownloadProps>`
  display: flex;
  width: 219px;
  height: 48px;
  justify-content: center;
  align-items: center;
  background: ${props => (props.disable ? COLOR.Gray_200 : COLOR.Blue_700)};
  border-radius: 25px;
  text-decoration: none;
  pointer-events: ${props => (props.disable ? 'none' : 'all')};

  :hover {
    cursor: ${props => (props.disable ? 'default' : 'pointer')};
    background: ${COLOR.Blue_900};
  }
`;

const IconContainer = styled.div<{ background: string }>`
  display: flex;
  width: 27px;
  height: 27px;
  justify-content: center;
  align-items: center;
  padding: 0px 1px 1px 0px;
  background: ${props => props.background};
  border-radius: 50px;
`;

const SideButtonsContainer = styled.div<ActiveProps>`
  display: flex;
  width: 58px;
  height: 58px;
  justify-content: center;
  align-items: center;
  margin: 35px;
  background: ${COLOR.WHITE};
  border-radius: 50px;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'auto' : 'none')};

  :hover {
    cursor: pointer;
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  display: flex;
  right: 44px;
  top: -70px;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${COLOR.BLACK};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;

  :hover {
    background-color: 'rgba(203,203,203,0.8)';
    opacity: 0.6;
    cursor: pointer;
  }
`;

const BoldH7Text = styled(TEXT.H7_Bold)``;

const BoldH5Text = styled(TEXT.H5_Bold)`
  margin-left: 16px;
`;

const BoldB3Text = styled(TEXT.B3_Bold)``;

const BlueBoldBu1Text = styled(TEXT.Bu1_Bold)`
  color: ${COLOR.Blue_700};
`;

const WhiteBoldBu1Text = styled(TEXT.Bu1_Bold)<DownloadProps>`
  color: ${props => (props.disable ? COLOR.Gray_300 : COLOR.WHITE)};
`;

const B4Text = styled(TEXT.B4_Reg)``;

const B5Text = styled(TEXT.B5_Reg)``;
