import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import HomeButton from '../../atoms/Layout/HomeButton';
import MenuDetail from '../../atoms/Layout/MenuDetail';
import { TEXT } from '../../../fonts/text';
import { COLOR } from '../../../fonts/color';
import { useQuery } from '../../../utils';
import { useDealStore } from '../../../stores/initialize/Context';
import { observer } from 'mobx-react';
import RBrandLogo from '../../atoms/RBrandLogo';

interface DefaultDrawerProps {
  navigation: NavigateFunction;
  pathname: string;
}

const DefaultDrawer = (props: DefaultDrawerProps) => {
  const { navigation, pathname } = props;

  const query = useQuery();
  const queryStatus = query.get('status') as string;
  const { deals } = useDealStore();

  const ongoingLength = deals.ongoing.metadata.count ?? 0;
  const pendingLength = deals.pending.metadata.count ?? 0;
  const doneLength = deals.done.metadata.count ?? 0;

  const campaignChildren: {
    text: string;
    onClick: () => void;
    active: boolean;
    haveTotal?: number;
  }[] = [
    {
      text: 'กำลังเผยแพร่',
      active:
        pathname.includes('/campaign') &&
        (queryStatus === 'ongoing' || !queryStatus),
      onClick: () => navigation('/campaign?status=ongoing'),
      haveTotal: ongoingLength
    },
    {
      text: 'ยังไม่เผยแพร่',
      active: queryStatus === 'pending',
      onClick: () => navigation('/campaign?status=pending'),
      haveTotal: pendingLength
    },
    {
      text: 'เสร็จสิ้น',
      active: queryStatus === 'done',
      onClick: () => navigation('/campaign?status=done'),
      haveTotal: doneLength
    }
  ];

  return (
    <>
      <PlaceholderContainer />
      <HeaderContainer>
        <RBrandLogo navigation={navigation} />
        <HomeButton navigation={navigation} />
        <GrayMBu3Text>Campaign</GrayMBu3Text>
        <MenuDetail
          icon="PlusCircle"
          text="สร้างแคมเปญใหม่"
          onClick={() => navigation('/createcampaign')}
          active={pathname.includes('/createcampaign')}
        />
        <MenuDetail
          icon="FileFill"
          text="แคมเปญของฉัน"
          onClick={() => navigation('/')}
          active={false}
          children={campaignChildren}
        />
        <MenuDetail
          icon="Engagement"
          text="Performance"
          onClick={() => navigation('/performance')}
          active={pathname.includes('/performance')}
        />
        <GrayMBu3Text>Influencer</GrayMBu3Text>
        <MenuDetail
          icon="Navigation"
          text="Discover"
          onClick={() => navigation('/discover')}
          active={pathname.includes('/discover')}
        />
        <MenuDetail
          icon="File"
          text="Search & Select"
          onClick={() => navigation('/directory')}
          active={pathname.includes('/directory')}
        />
      </HeaderContainer>
    </>
  );
};

export default observer(DefaultDrawer);

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 240px;
  padding: 20px 16px;
  height: 100%;
  z-index: 9999;
  border-right: 0.6px solid ${COLOR.Gray_200};
  background-color: ${COLOR.White};
`;

const PlaceholderContainer = styled.div`
  width: 240px;
  flex-shrink: 0;
`;

const GrayMBu3Text = styled(TEXT.Bu3_Reg)`
  margin-top: 20px;
  margin-left: 12px;
  color: ${COLOR.Gray_M};
`;
