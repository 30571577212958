import styled from 'styled-components';
import { RowContainer } from '../../../utils/styling/general';
import LifestyleChip from './LifestyleChip';
import { observer } from 'mobx-react';

interface Props {
  activeList: string[];
  lifestyleList: {
    label: string;
    value: string;
  }[];
  setActiveList: (att: string[]) => void;
}

const LifestyleList = (props: Props) => {
  const { activeList, lifestyleList, setActiveList } = props;

  const onChipClick = (att: string, active: boolean) => {
    const tempList = [...activeList];
    if (active) {
      tempList.splice(tempList.indexOf(att), 1);
    } else if (activeList.length < 5) {
      tempList.push(att);
    }
    setActiveList(tempList);
  };

  const ListComponent = () => {
    const components: JSX.Element[] = [];
    lifestyleList.map(ls => {
      const active = activeList.includes(ls.value);
      components.push(
        <LifestyleChip
          key={ls.value}
          name={ls.label}
          active={active}
          onClick={() => onChipClick(ls.value, active)}
        />
      );
    });
    return <>{components}</>;
  };

  return (
    <Container>
      <ListComponent />
    </Container>
  );
};

export default observer(LifestyleList);

const Container = styled(RowContainer)`
  gap: 8px;
  flex-wrap: wrap;
`;
