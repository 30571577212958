import { IconName } from 'rn-rx-icons';
import { plusDays } from '../../../utils';
import { SortByType, SortOrderType } from '../../../types/sort';

export const changwatOptions = [
  'all',
  'Bangkok',
  'Amnat Charoen',
  'Ang Thong',
  'Bueng Kan',
  'Buri Ram',
  'Chachoengsao',
  'Chai Nat',
  'Chaiyaphum',
  'Chanthaburi',
  'Chiang Mai',
  'Chiang Rai',
  'Chon Buri',
  'Chumphon',
  'Kalasin',
  'Kamphaeng Phet',
  'Kanchanaburi',
  'Khon Kaen',
  'Krabi',
  'Lampang',
  'Lamphun',
  'Loei',
  'Lop Buri',
  'Mae Hong Son',
  'Maha Sarakham',
  'Mukdahan',
  'Nakhon Nayok',
  'Nakhon Pathom',
  'Nakhon Phanom',
  'Nakhon Ratchasima',
  'Nakhon Sawan',
  'Nakhon Si Thammarat',
  'Nan',
  'Narathiwat',
  'Nong Bua Lam Phu',
  'Nong Khai',
  'Nonthaburi',
  'Pathum Thani',
  'Pattani',
  'Phang-nga',
  'Phatthalung',
  'Phayao',
  'Phetchabun',
  'Phetchaburi',
  'Phichit',
  'Phitsanulok',
  'Phra Nakhon Si Ayutthaya',
  'Phrae',
  'Phuket',
  'Prachin Buri',
  'Prachuap Khiri Khan',
  'Ranong',
  'Ratchaburi',
  'Rayong',
  'Roi Et',
  'Sa kaeo',
  'Sakon Nakhon',
  'Samut Prakan',
  'Samut Sakhon',
  'Samut Songkhram',
  'Saraburi',
  'Satun',
  'Si Sa Ket',
  'Sing Buri',
  'Songkhla',
  'Sukhothai',
  'Suphan Buri',
  'Surat Thani',
  'Surin',
  'Tak',
  'Trang',
  'Trat',
  'Ubon Ratchathani',
  'Udon Thani',
  'Uthai Thani',
  'Uttaradit',
  'Yala',
  'Yasothon'
];

export const changwat = [
  {
    label: 'ทุกจังหวัด',
    value: 'all'
  },
  {
    label: 'กรุงเทพมหานคร',
    value: 'Bangkok'
  },
  {
    label: 'สมุทรปราการ',
    value: 'Samut Prakan'
  },
  {
    label: 'นนทบุรี',
    value: 'Nonthaburi'
  },
  {
    label: 'ปทุมธานี',
    value: 'Pathum Thani'
  },
  {
    label: 'พระนครศรีอยุธยา',
    value: 'Phra Nakhon Si Ayutthaya'
  },
  {
    label: 'อ่างทอง',
    value: 'Ang Thong'
  },
  {
    label: 'ลพบุรี',
    value: 'Lop Buri'
  },
  {
    label: 'สิงห์บุรี',
    value: 'Sing Buri'
  },
  {
    label: 'ชัยนาท',
    value: 'Chai Nat'
  },
  {
    label: 'สระบุรี',
    value: 'Saraburi'
  },
  {
    label: 'ชลบุรี',
    value: 'Chon Buri'
  },
  {
    label: 'ระยอง',
    value: 'Rayong'
  },
  {
    label: 'จันทบุรี',
    value: 'Chanthaburi'
  },
  {
    label: 'ตราด',
    value: 'Trat'
  },
  {
    label: 'ฉะเชิงเทรา',
    value: 'Chachoengsao'
  },
  {
    label: 'ปราจีนบุรี',
    value: 'Prachin Buri'
  },
  {
    label: 'นครนายก',
    value: 'Nakhon Nayok'
  },
  {
    label: 'สระแก้ว',
    value: 'Sa kaeo'
  },
  {
    label: 'นครราชสีมา',
    value: 'Nakhon Ratchasima'
  },
  {
    label: 'บุรีรัมย์',
    value: 'Buri Ram'
  },
  {
    label: 'สุรินทร์',
    value: 'Surin'
  },
  {
    label: 'ศรีสะเกษ',
    value: 'Si Sa Ket'
  },
  {
    label: 'อุบลราชธานี',
    value: 'Ubon Ratchathani'
  },
  {
    label: 'ยโสธร',
    value: 'Yasothon'
  },
  {
    label: 'ชัยภูมิ',
    value: 'Chaiyaphum'
  },
  {
    label: 'อำนาจเจริญ',
    value: 'Amnat Charoen'
  },
  {
    label: 'บึงกาฬ',
    value: 'Bueng Kan'
  },
  {
    label: 'หนองบัวลำภู',
    value: 'Nong Bua Lam Phu'
  },
  {
    label: 'ขอนแก่น',
    value: 'Khon Kaen'
  },
  {
    label: 'อุดรธานี',
    value: 'Udon Thani'
  },
  {
    label: 'เลย',
    value: 'Loei'
  },
  {
    label: 'หนองคาย',
    value: 'Nong Khai'
  },
  {
    label: 'มหาสารคาม',
    value: 'Maha Sarakham'
  },
  {
    label: 'ร้อยเอ็ด',
    value: 'Roi Et'
  },
  {
    label: 'กาฬสินธุ์',
    value: 'Kalasin'
  },
  {
    label: 'สกลนคร',
    value: 'Sakon Nakhon'
  },
  {
    label: 'นครพนม',
    value: 'Nakhon Phanom'
  },
  {
    label: 'มุกดาหาร',
    value: 'Mukdahan'
  },
  {
    label: 'เชียงใหม่',
    value: 'Chiang Mai'
  },
  {
    label: 'ลำพูน',
    value: 'Lamphun'
  },
  {
    label: 'ลำปาง',
    value: 'Lampang'
  },
  {
    label: 'อุตรดิตถ์',
    value: 'Uttaradit'
  },
  {
    label: 'แพร่',
    value: 'Phrae'
  },
  {
    label: 'น่าน',
    value: 'Nan'
  },
  {
    label: 'พะเยา',
    value: 'Phayao'
  },
  {
    label: 'เชียงราย',
    value: 'Chiang Rai'
  },
  {
    label: 'แม่ฮ่องสอน',
    value: 'Mae Hong Son'
  },
  {
    label: 'นครสวรรค์',
    value: 'Nakhon Sawan'
  },
  {
    label: 'อุทัยธานี',
    value: 'Uthai Thani'
  },
  {
    label: 'กำแพงเพชร',
    value: 'Kamphaeng Phet'
  },
  {
    label: 'ตาก',
    value: 'Tak'
  },
  {
    label: 'สุโขทัย',
    value: 'Sukhothai'
  },
  {
    label: 'พิษณุโลก',
    value: 'Phitsanulok'
  },
  {
    label: 'พิจิตร',
    value: 'Phichit'
  },
  {
    label: 'เพชรบูรณ์',
    value: 'Phetchabun'
  },
  {
    label: 'ราชบุรี',
    value: 'Ratchaburi'
  },
  {
    label: 'กาญจนบุรี',
    value: 'Kanchanaburi'
  },
  {
    label: 'สุพรรณบุรี',
    value: 'Suphan Buri'
  },
  {
    label: 'นครปฐม',
    value: 'Nakhon Pathom'
  },
  {
    label: 'สมุทรสาคร',
    value: 'Samut Sakhon'
  },
  {
    label: 'สมุทรสงคราม',
    value: 'Samut Songkhram'
  },
  {
    label: 'เพชรบุรี',
    value: 'Phetchaburi'
  },
  {
    label: 'ประจวบคีรีขันธ์',
    value: 'Prachuap Khiri Khan'
  },
  {
    label: 'นครศรีธรรมราช',
    value: 'Nakhon Si Thammarat'
  },
  {
    label: 'กระบี่',
    value: 'Krabi'
  },
  {
    label: 'พังงา',
    value: 'Phang-nga'
  },
  {
    label: 'ภูเก็ต',
    value: 'Phuket'
  },
  {
    label: 'สุราษฎร์ธานี',
    value: 'Surat Thani'
  },
  {
    label: 'ระนอง',
    value: 'Ranong'
  },
  {
    label: 'ชุมพร',
    value: 'Chumphon'
  },
  {
    label: 'สงขลา',
    value: 'Songkhla'
  },
  {
    label: 'สตูล',
    value: 'Satun'
  },
  {
    label: 'ตรัง',
    value: 'Trang'
  },
  {
    label: 'พัทลุง',
    value: 'Phatthalung'
  },
  {
    label: 'ปัตตานี',
    value: 'Pattani'
  },
  {
    label: 'ยะลา',
    value: 'Yala'
  },
  {
    label: 'นราธิวาส',
    value: 'Narathiwat'
  }
];

export const attribute = [
  // {
  //   label: "🐾 สัตว์เลี้ยง",
  //   value: "สัตว์เลี้ยง",
  // },
  // {
  //   label: "📸 ถ่ายเซลฟี่",
  //   value: "ถ่ายเซลฟี่",
  // },
  // {
  //   label: "🏊🏻‍♀️ ชุดว่ายน้ำ",
  //   value: "ชุดว่ายน้ำ",
  // },
  {
    label: '🍔 สายอาหาร',
    value: 'สายอาหาร'
  },
  {
    label: '☕️ คาเฟ่',
    value: 'คาเฟ่'
  },
  {
    label: '🏖️ทะเล',
    value: 'ทะเล'
  },
  {
    label: '⛰️ ภูเขา',
    value: 'ภูเขา'
  },
  {
    label: '🏕️ แคมป์ปิ้ง',
    value: 'แคมป์ปิ้ง'
  },
  {
    label: '🚎 สายเที่ยว',
    value: 'สายเที่ยว'
  }
  // {
  //   label: "🧚🏻‍♀️ แฟชั่น",
  //   value: "แฟชั่น",
  // },
  // {
  //   label: "👩🏻‍❤️‍👨🏻 คู่รัก",
  //   value: "คู่รัก",
  // },
  // {
  //   label: "📊 Engagement สูง",
  //   value: "Engagement สูง",
  // },
  // {
  //   label: "📱 ใช้ Android",
  //   value: "ใช้ Android",
  // },
  // {
  //   label: "📱 ใช้ iPhone",
  //   value: "ใช้ iPhone",
  // },
  // {
  //   label: "📡 มีครบทุก Social",
  //   value: "มีครบทุก Social",
  // },
  // {
  //   label: "💯 คะแนนรีวิวสูง",
  //   value: "คะแนนรีวิวสูง",
  // },
  // {
  //   label: "🏙️ อยู่กทม.",
  //   value: "อยู่กทม.",
  // },
  // {
  //   label: "🏘️ อยู่ต่างจังหวัด",
  //   value: "อยู่ต่างจังหวัด",
  // },
];

export const reviewTagOptions: { value: string; icon: IconName }[] = [
  { value: 'ถ่ายรูปสวย', icon: 'Camera' },
  { value: 'ส่งงานตรงเวลา', icon: 'ClockFill' },
  { value: 'ทำตามบรีฟ', icon: 'TermsFill' },
  { value: 'ตอบแชทรวดเร็ว', icon: 'ChatFill' },
  { value: 'สร้างสรรค์', icon: 'CreativeFill' },
  { value: 'มีความมั่นใจ', icon: 'More' }
];

export type adsDuration = 'none' | 'week' | 'half' | 'month';

export const pinnedOptions: {
  text: string;
  value: adsDuration;
  detail?: string;
  startDate?: string;
  endDate?: string;
}[] = [
  { text: 'ไม่ต้องการ', value: 'none' },
  {
    text: 'สัปดาห์',
    detail: '7 วัน / 500 บาท',
    startDate: new Date().toISOString(),
    endDate: plusDays(new Date(), 7),
    value: 'week'
  },
  {
    text: 'ครึ่งเดือน',
    detail: '15 วัน / 900 บาท',
    startDate: new Date().toISOString(),
    endDate: plusDays(new Date(), 15),
    value: 'half'
  },
  {
    text: 'เต็มเดือน',
    detail: '30 วัน / 1500 บาท',
    startDate: new Date().toISOString(),
    endDate: plusDays(new Date(), 30),
    value: 'month'
  }
];

// TODO: Move from here to a folder for directory related stuff
export const InfluencerSortOptions: { label: string; value: SortByType }[] = [
  { value: 'followers', label: 'Followers' },
  { value: 'avgEngagement', label: 'Avg.Engagement' },
  // {"score"},
  { value: 'rating', label: 'Rating' }
];

export const interestCategory: Array<{
  value: string;
  icon: string;
}> = [
  {
    value: 'Application & Service',
    icon: '📱'
  },
  {
    value: 'Art & Stationary',
    icon: '🎨'
  },
  {
    value: 'Beauty & Cosmetics',
    icon: '💄'
  },
  {
    value: 'Cafe & Restaurant',
    icon: '🍰'
  },
  {
    value: 'Family & Toy',
    icon: '🍼'
  },
  {
    value: 'Fashion & Accessory',
    icon: '👗'
  },
  {
    value: 'Finance',
    icon: '💰'
  },
  {
    value: 'Food & Beverage',
    icon: '🍔'
  },
  {
    value: 'Health & Sport',
    icon: '🏀'
  },
  {
    value: 'Home & Decoration',
    icon: '🛋️'
  },
  {
    value: 'Pet & Animal',
    icon: '🐶'
  },
  {
    value: 'Real Estate & Property',
    icon: '🏢'
  },
  {
    value: 'Technology & Electronics',
    icon: '📡'
  },
  {
    value: 'Traveling & Event',
    icon: '🏝️'
  },
  {
    value: 'Vehicle & Transport',
    icon: '🚗'
  }
];
const a = [
  'สัตว์เลี้ยง',
  'ถ่ายเซลฟี่',
  'ชุดว่ายน้ำ',
  'สายอาหาร',
  'คาเฟ่',
  'ทะเล',
  'ภูเขา',
  'แคมป์ปิ้ง',
  'สายเที่ยว',
  'แฟชั่น',
  'คู่รัก',
  'Engagement สูง',
  'มีครบทุก Social',
  'คะแนนรีวิวสูง',
  'อยู่กทม.',
  'อยู่ต่างจังหวัด'
];

export type CategoryType =
  | 'Application & Service'
  | 'Art & Stationary'
  | 'Beauty & Cosmetics'
  | 'Cafe & Restaurant'
  | 'Family & Toy'
  | 'Fashion & Accessory'
  | 'Finance'
  | 'Food & Beverage'
  | 'Health & Sport'
  | 'Home & Decoration'
  | 'Pet & Animal'
  | 'Real Estate & Property'
  | 'Technology & Electronics'
  | 'Traveling & Event'
  | 'Vehicle & Transport';
// | "Other";

export const categoryOptions: { value: CategoryType; icon: IconName }[] = [
  {
    value: 'Application & Service',
    icon: 'ApplicationService'
  },
  {
    value: 'Art & Stationary',
    icon: 'ArtStationary'
  },
  {
    value: 'Beauty & Cosmetics',
    icon: 'BeautyCosmetics'
  },
  {
    value: 'Cafe & Restaurant',
    icon: 'CafeRestaurant'
  },
  {
    value: 'Family & Toy',
    icon: 'FamilyToy'
  },
  {
    value: 'Fashion & Accessory',
    icon: 'FashionAccessory'
  },
  {
    value: 'Finance',
    icon: 'Finance'
  },
  {
    value: 'Food & Beverage',
    icon: 'FoodBeverage'
  },
  {
    value: 'Health & Sport',
    icon: 'HealthSport'
  },
  {
    value: 'Home & Decoration',
    icon: 'HomeDecoration'
  },
  {
    value: 'Pet & Animal',
    icon: 'PetAnimal'
  },
  {
    value: 'Real Estate & Property',
    icon: 'RealEstateProperty'
  },
  {
    value: 'Technology & Electronics',
    icon: 'TechnologyElectronics'
  },
  {
    value: 'Traveling & Event',
    icon: 'TravelingEvent'
  },
  {
    value: 'Vehicle & Transport',
    icon: 'VehicleTransport'
  }
];

export const regionalProvinces = {
  ทุกจังหวัด: [
    {
      label: 'ทุกจังหวัด',
      value: 'all'
    }
  ],
  กรุงเทพมหานครและปริมณฑล: [
    { label: 'กรุงเทพมหานคร', value: 'Bangkok' },
    { label: 'ปทุมธานี', value: 'Pathum Thani' },
    { label: 'นครปฐม', value: 'Nakhon Pathom' },
    { label: 'นนทบุรี', value: 'Nonthaburi' },
    { label: 'สมุทรปราการ', value: 'Samut Prakan' },
    { label: 'สมุทรสาคร', value: 'Samut Sakhon' }
  ],
  ภาคกลาง: [
    { label: 'ชัยนาท', value: 'Chai Nat' },
    { label: 'พระนครศรีอยุธยา', value: 'Phra Nakhon Si Ayutthaya' },
    { label: 'ลพบุรี', value: 'Lop Buri' },
    { label: 'สิงห์บุรี', value: 'Sing Buri' },
    { label: 'สระบุรี', value: 'Saraburi' },
    { label: 'อ่างทอง', value: 'Ang Thong' }
  ],
  ภาคเหนือ: [
    { label: 'กำแพงเพชร', value: 'Kamphaeng Phet' },
    { label: 'เชียงราย', value: 'Chiang Rai' },
    { label: 'เชียงใหม่', value: 'Chiang Mai' },
    { label: 'ตาก', value: 'Tak' },
    { label: 'นครสวรรค์', value: 'Nakhon Sawan' },
    { label: 'น่าน', value: 'Nan' },
    { label: 'พะเยา', value: 'Phayao' },
    { label: 'พิจิตร', value: 'Phichit' },
    { label: 'พิษณุโลก', value: 'Phitsanulok' },
    { label: 'เพชรบูรณ์', value: 'Phetchabun' },
    { label: 'แพร่', value: 'Phrae' },
    { label: 'แม่ฮ่องสอน', value: 'Mae Hong Son' },
    { label: 'ลำปาง', value: 'Lampang' },
    { label: 'ลำพูน', value: 'Lamphun' },
    { label: 'สุโขทัย', value: 'Sukhothai' },
    { label: 'อุตรดิตถ์', value: 'Uttaradit' },
    { label: 'อุทัยธานี', value: 'Uthai Thani' }
  ],
  ภาคตะวันออกเฉียงเหนือ: [
    { label: 'อำนาจเจริญ', value: 'Amnat Charoen' },
    { label: 'บึงกาฬ', value: 'Bueng Kan' },
    { label: 'บุรีรัมย์', value: 'Buri Ram' },
    { label: 'ชัยภูมิ', value: 'Chaiyaphum' },
    { label: 'กาฬสินธุ์', value: 'Kalasin' },
    { label: 'ขอนแก่น', value: 'Khon Kaen' },
    { label: 'เลย', value: 'Loei' },
    { label: 'มหาสารคาม', value: 'Maha Sarakham' },
    { label: 'มุกดาหาร', value: 'Mukdahan' },
    { label: 'นครพนม', value: 'Nakhon Phanom' },
    { label: 'นครราชสีมา', value: 'Nakhon Ratchasima' },
    { label: 'หนองบัวลำภู', value: 'Nong Bua Lam Phu' },
    { label: 'หนองคาย', value: 'Nong Khai' },
    { label: 'ร้อยเอ็ด', value: 'Roi Et' },
    { label: 'สกลนคร', value: 'Sakon Nakhon' },
    { label: 'ศรีสะเกษ', value: 'Si Sa Ket' },
    { label: 'สุรินทร์', value: 'Surin' },
    { label: 'อุบลราชธานี', value: 'Ubon Ratchathani' },
    { label: 'อุดรธานี', value: 'Udon Thani' },
    { label: 'ยโสธร', value: 'Yasothon' }
  ],
  ภาคตะวันออก: [
    { label: 'จันทบุรี', value: 'Chanthaburi' },
    { label: 'ฉะเชิงเทรา', value: 'Chachoengsao' },
    { label: 'ชลบุรี', value: 'Chon Buri' },
    { label: 'ตราด', value: 'Trat' },
    { label: 'นครนายก', value: 'Nakhon Nayok' },
    { label: 'ปราจีนบุรี', value: 'Prachin Buri' },
    { label: 'ระยอง', value: 'Rayong' },
    { label: 'สระแก้ว', value: 'Sa kaeo' }
  ],
  ภาคตะวันตก: [
    { label: 'กาญจนบุรี', value: 'Kanchanaburi' },
    { label: 'ประจวบคีรีขันธ์', value: 'Prachuap Khiri Khan' },
    { label: 'เพชรบุรี', value: 'Phetchaburi' },
    { label: 'ราชบุรี', value: 'Ratchaburi' },
    { label: 'สมุทรสงคราม', value: 'Samut Songkhram' },
    { label: 'สุพรรณบุรี', value: 'Suphan Buri' }
  ],
  ภาคใต้: [
    { label: 'ชุมพร', value: 'Chumphon' },
    { label: 'กระบี่', value: 'Krabi' },
    { label: 'นครศรีธรรมราช', value: 'Nakhon Si Thammarat' },
    { label: 'นราธิวาส', value: 'Narathiwat' },
    { label: 'ปัตตานี', value: 'Pattani' },
    { label: 'พังงา', value: 'Phang-nga' },
    { label: 'พัทลุง', value: 'Phatthalung' },
    { label: 'ภูเก็ต', value: 'Phuket' },
    { label: 'ระนอง', value: 'Ranong' },
    { label: 'สตูล', value: 'Satun' },
    { label: 'สงขลา', value: 'Songkhla' },
    { label: 'สุราษฎร์ธานี', value: 'Surat Thani' },
    { label: 'ตรัง', value: 'Trang' },
    { label: 'ยะลา', value: 'Yala' }
  ]
};
