import React from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { ANDCOLOR } from '../../../../fonts/andColor';
import { ANDTEXT } from '../../../../fonts/andText';
import {
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../../utils/styling/general';
import ANDButton from '../../../atoms/ANDButton';
import RTextarea from '../../../atoms/RTextarea';
import { COLOR } from '../../../../fonts/color';

interface Props {
  detail: string;
  setDetail: (detail: string) => void;
}

function CampaignDetail(props: Props) {
  const { detail, setDetail } = props;

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>รายละเอียด</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <BoldHeader8Text>
            เหตุผลในการโปรโมต<RedHeader7Text>*</RedHeader7Text>
          </BoldHeader8Text>
        </GapRowContainer>
        <Body3Text>
          เพื่อให้ Influencer เข้าใจจุดประสงค์แคมเปญของแบรนด์มากขึ้น
        </Body3Text>
        <RTextarea
          inputProp={{
            placeholder:
              'แบรนด์ XXX กำลังจะเปิดตัวสินค้าใหม่สำหรับดูแลผิวที่เหมาะสำหรับป้องกันแดดกลางแจ้งและ ผิวแพ้ง่าย จึงต้องการตามหา Influencer ที่สามารถรีวิวสินค้าในโลเคชั่นแดดจัด เช่น ทะเล หรือ กิจกรรมการออกกำลังกายกลางแจ้ง',
            style: {
              display: 'flex',
              width: 568,
              height: 180,
              paddingTop: 16,
              paddingBottom: 16,
              paddingRight: 24,
              paddingLeft: 24,
              gap: 10,
              borderRadius: 16,
              border: `1px solid ${COLOR.Gray_400}`,
              background: `${ANDCOLOR.WHITE}`,
              overflow: 'hidden'
            },
            defaultValue: detail,
            onBlur: e => {
              setDetail(e.target.value);
            }
          }}
        />
      </ContentContainer>
    </Container>
  );
}

export default CampaignDetail;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 20px;
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
  margin-bottom: 24px;
`;

const RedHeader7Text = styled(ANDTEXT.header7)`
  width: 88px;
  white-space: nowrap;
  color: ${ANDCOLOR.PINK};
  margin-left: 2px;
`;
