import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import RButton from '../../../components/atoms/RButton';
import RValueDetail from '../../../components/atoms/RValueDetail';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ITierType } from '../../../types/createDeal';
import { limitText, toDecimalNumber, toMonthYear } from '../../../utils';
import {
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import RPlatformIcon from '../../../components/atoms/RPlatformIcon';
import { PlatformType } from '../../../types/deal';

interface GetDisplayCashProps {
  tier: ITierType;
  cash: number;
}

const getDisplayCash = (props: GetDisplayCashProps) => {
  const { tier, cash } = props;
  if (!tier) {
    return cash;
  }
  return toDecimalNumber({
    number:
      tier.nano.value * tier.nano.cash +
      tier.micro.value * tier.micro.cash +
      tier.mid.value * tier.mid.cash +
      tier.macro.value * tier.macro.cash
  });
};

interface StatusProps {
  status: 'ongoing' | 'done';
}

const StatusTagComponent = (props: StatusProps) => {
  const { status } = props;
  if (status === 'ongoing')
    return (
      <OngoingContainer>
        <LemonB4Text>กำลังดำเนินการ</LemonB4Text>
      </OngoingContainer>
    );
  else
    return (
      <DoneContainer>
        <GreenMB4Text>จบแคมเปญ</GreenMB4Text>
      </DoneContainer>
    );
};
interface Props {
  name: string;
  photos: { url: string; file: File }[] | { url: string }[];
  category: string;
  value: number;
  checkDetail?: () => void;
  tier: ITierType;
  cash: number;
  platforms: ('Instagram' | 'Tiktok' | 'Lemon' | 'Facebook')[];
  isDone: boolean;
  createdAt: Date;
  dueDate: Date;
  postingDate: Date;
  submissionDate: Date;
  influencerCount: number;
  totalInfluencer: number;
  postCount: number;
  totalPost: number;
  showDetail: boolean;
  setShowDetail: (bool: boolean) => void;
}

const PerformanceCardHeader = (props: Props) => {
  const {
    name,
    photos,
    category,
    value = '0',
    tier,
    cash,
    platforms,
    isDone,
    createdAt,
    influencerCount,
    totalInfluencer,
    postCount,
    totalPost,
    dueDate,
    postingDate,
    submissionDate,
    showDetail,
    setShowDetail
  } = props;

  const displayPlatform = platforms[0]?.toUpperCase() as PlatformType;
  const displayValue = value ? `${value} ฿` : '-';
  const displayCash = getDisplayCash({ tier, cash });
  const campaignTotalDate = Math.floor(
    (new Date(dueDate).getTime() - new Date(createdAt).getTime()) / 86400000
  );
  const thaiCreatedDate = toMonthYear({
    dueDate: createdAt,
    haveDay: true,
    dateLanguage: 'th'
  });
  const thaidueDate = toMonthYear({
    dueDate: dueDate,
    haveDay: true,
    dateLanguage: 'th'
  });
  const thaiPostingDate = toMonthYear({
    dueDate: postingDate,
    haveDay: true,
    dateLanguage: 'th'
  });
  const thaiSubmissionDate = toMonthYear({
    dueDate: submissionDate,
    haveDay: true,
    dateLanguage: 'th'
  });

  return (
    <Container>
      <PreviewImage src={photos[0]?.url} />
      <HeaderContainer>
        <SpaceBetweenRowContainer>
          <GapRowContainer>
            <RPlatformIcon
              platform={displayPlatform}
              iconSize={28}
              dimension={38}
            />
            <StatusTagComponent status={isDone ? 'done' : 'ongoing'} />
          </GapRowContainer>
          <GapRowContainer>
            <IconContainer>
              <RXIcon name="Download" color={COLOR.White} />
            </IconContainer>
            <IconContainer>
              <RXIcon name="Share" color={COLOR.White} />
            </IconContainer>
            <RButton
              title="รายละเอียด"
              onPress={() => setShowDetail(!showDetail)}
              textStyle={{ fontSize: 16 }}
              containerStyle={{
                width: 116,
                height: 40,
                minWidth: 116
              }}
            />
          </GapRowContainer>
        </SpaceBetweenRowContainer>
        <FlexStartColumnContainer>
          <WhiteB3Reg>{`${category} | สร้างเมื่อ ${thaiCreatedDate}`}</WhiteB3Reg>
          <WhiteBoldH4Text>{limitText(name, 60)}</WhiteBoldH4Text>
        </FlexStartColumnContainer>
      </HeaderContainer>
      <DetailContainer>
        <PriceContainer>
          <RValueDetail value={displayValue} description="รวมมูลค่าสินค้า" />
          <RValueDetail value={displayCash} description="รวมค่าตอบแทน" />
          <RValueDetail
            value={`${influencerCount} / ${totalInfluencer}`}
            description="Influencer ทั้งหมด"
          />
          <RValueDetail
            value={`${postCount} / ${totalPost}`}
            description="โพสต์ทั้งหมด"
          />
        </PriceContainer>
        <DateContainer>
          <RValueDetail
            value={`${thaiCreatedDate} - ${thaidueDate}`}
            description="กำหนดรับสมัคร"
            containerStyle={{ alignItems: 'flex-end' }}
            valueStyle={{ fontSize: 12 }}
          />
          <VerticalDashline />
          <RValueDetail
            value={`${thaiPostingDate} - ${thaiSubmissionDate}`}
            description={`กำหนดโพสต์งาน (${campaignTotalDate} วัน)`}
            containerStyle={{ alignItems: 'flex-end' }}
            valueStyle={{ fontSize: 14 }}
          />
        </DateContainer>
      </DetailContainer>
    </Container>
  );
};

export default PerformanceCardHeader;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 309px;
  padding: 24px 36px;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.5);
`;

const PreviewImage = styled.img`
  position: absolute;
  top: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: cover;
  background: ${COLOR.Gray_300};
  z-index: -1;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  position: relative;
`;

const DetailContainer = styled(SpaceBetweenRowContainer)`
  border-top: 0.6px solid ${COLOR.Gray_300};
  gap: 24px;
  padding-top: 16px;
`;

const PriceContainer = styled(RowContainer)`
  gap: 24px;
`;

const DateContainer = styled(RowContainer)`
  gap: 16px;
`;

const InstagramContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  background: linear-gradient(
      225deg,
      #4f5bd5 0.62%,
      #962fbf 23.93%,
      #d62976 48.93%,
      #fa7e1e 70.81%,
      #feda75 96.33%
    )
    border-box;
`;

const TiktokContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  background-color: ${COLOR.Black};
`;

const FacebookContainer = styled.div`
  position: relative;
  display: flex;
  width: 36px;
  height: 36px;
`;

const FacebookBackground = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background-color: ${COLOR.White};
  z-index: 1;
  bottom: 6px;
  left: 4px;
`;

const FacebookIconContainer = styled.div`
  z-index: 2;
`;

const LemonImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50px;
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const OngoingContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 0.6px solid ${COLOR.Lemon};
  background: ${COLOR.Yellow_L};
`;

const DoneContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 0.6px solid ${COLOR.Herbal};
  background: ${COLOR.Green_L};
`;

const IconContainer = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.2);
`;

const VerticalDashline = styled.div`
  width: 0.6px;
  height: 36px;
  background-color: ${COLOR.Gray_50};
`;
const WhiteB3Reg = styled(TEXT.B3_Reg)`
  color: ${COLOR.WHITE};
`;

const WhiteBoldH4Text = styled(TEXT.H4_Bold)`
  color: ${COLOR.WHITE};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GreenMB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Green_M};
`;

const LemonB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Lemon};
`;
