import styled from 'styled-components';
import { TEXT } from '../../../fonts/text';
import { COLOR } from '../../../fonts/color';
import { ActiveProps } from '../../../types';

interface Props {
  name: string;
  active: boolean;
  onClick: () => void;
}

function LifestyleChip(props: Props) {
  const { name, active, onClick } = props;
  return (
    <Container onClick={onClick} active={active}>
      <Bu3Text active={active}>{name}</Bu3Text>
    </Container>
  );
}

export default LifestyleChip;

const Container = styled.div<ActiveProps>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: ${props =>
    props.active ? `1px solid ${COLOR.Black}` : `0.4px solid ${COLOR.Gray_M}`};
  background: ${props => (props.active ? COLOR.Gray_100 : COLOR.White)};
  :hover {
    cursor: pointer;
  }
`;

const Bu3Text = styled(TEXT.Bu3_Reg)<ActiveProps>`
  color: ${props => (props.active ? COLOR.Black : COLOR.Gray_D)};
`;
