import { FirebaseApp, initializeApp as initFirebase } from 'firebase/app';
import firebase from 'firebase/app';
import * as Analytics from 'firebase/analytics';
import { v4 as uuid } from 'uuid';
import { getAnalytics, logEvent } from 'firebase/analytics';

const userId = uuid();
let brandName = '';
const env = process.env.REACT_APP_ENVIRONMENT;

const FIREBASE_apiKey =
  env === 'production'
    ? process.env.REACT_APP_PRODUCTION_FIREBASE_apiKey
    : process.env.REACT_APP_STAGING_FIREBASE_apiKey;
const FIREBASE_appId =
  env === 'production'
    ? process.env.REACT_APP_PRODUCTION_FIREBASE_appId
    : process.env.REACT_APP_STAGING_FIREBASE_appId;
const FIREBASE_authDomain =
  env === 'production'
    ? process.env.REACT_APP_PRODUCTION_FIREBASE_authDomain
    : process.env.REACT_APP_STAGING_FIREBASE_authDomain;
const FIREBASE_databaseURL =
  env === 'production'
    ? process.env.REACT_APP_PRODUCTION_FIREBASE_databaseURL
    : process.env.REACT_APP_STAGING_FIREBASE_databaseURL;
const FIREBASE_measurementId =
  env === 'production'
    ? process.env.REACT_APP_PRODUCTION_FIREBASE_measurementId
    : process.env.REACT_APP_STAGING_FIREBASE_measurementId;
const FIREBASE_messagingSenderId =
  env === 'production'
    ? process.env.REACT_APP_PRODUCTION_FIREBASE_messagingSenderId
    : process.env.REACT_APP_STAGING_FIREBASE_messagingSenderId;
const FIREBASE_projectId =
  env === 'production'
    ? process.env.REACT_APP_PRODUCTION_FIREBASE_projectId
    : process.env.REACT_APP_STAGING_FIREBASE_projectId;
const FIREBASE_storageBucket =
  env === 'production'
    ? process.env.REACT_APP_PRODUCTION_FIREBASE_storageBucket
    : process.env.REACT_APP_STAGING_FIREBASE_storageBucket;

let app: FirebaseApp;
let analytics: Analytics.Analytics;

export const initializeApp = () => {
  if (app) {
    return app;
  }
  const config = {
    apiKey: FIREBASE_apiKey,
    authDomain: FIREBASE_authDomain,
    databaseURL: FIREBASE_databaseURL,
    projectId: FIREBASE_projectId,
    storageBucket: FIREBASE_storageBucket,
    messagingSenderId: FIREBASE_messagingSenderId,
    appId: FIREBASE_appId,
    measurementId: FIREBASE_measurementId
  };
  app = initFirebase(config);
  analytics = getAnalytics();
  // Analytics.setClientId(userId);
  return app;
};

export const setBrandName = (name: string) => {
  if (brandName !== '') return;
  brandName = name;
};

/**
 * logEvent
 * @param eventName - The name of the event.
 * @param params - The parameters to be logged.
 *
 * @example
 * https://docs.expo.dev/versions/latest/sdk/firebase-analytics/#how-do-people-use-my-app
 */
export const logAnalyticsEvent = (eventName: string, params?: any) => {
  logEvent(analytics, eventName, {
    // the user id, should be brandId or userid if missing
    user: userId,
    // the current app version
    version: '2.3.0',
    // the name of the brand
    brandName,
    // other params
    ...params
  });
};
