import styled from 'styled-components';
import { toThaiDate } from '../../../utils';
import {
  B4Text,
  BoldH4Text,
  FlexStartColumnContainer
} from '../../../utils/styling/general';

export const InfluencerInsightTitle = () => {
  const calculateDateText = () => {
    const now = new Date();
    const startDate = toThaiDate(
      new Date(now.getFullYear(), now.getMonth() - 3, 1)
    );
    const endDate = toThaiDate(now);

    return `คำนวณข้อมูลตั้งแต่วันที่ ${startDate} ถึง ${endDate}`;
  };
  const dateText = calculateDateText();

  return (
    <Container>
      <BoldH4Text>Influencer Insight</BoldH4Text>
      <B4Text>{dateText}</B4Text>
    </Container>
  );
};

const Container = styled(FlexStartColumnContainer)`
  width: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
`;
